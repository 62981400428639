import { Avatar, AvatarProps } from "antd";
import { TOKEN_KEY, baseURL } from "authProvider";


export default function AvatarCustom(props: AvatarProps) {
    return (
        <Avatar
            {...props}
            src={`${baseURL}/view-file/${props.src}?token=${localStorage.getItem(
                TOKEN_KEY
            )}`}
        />
    );
}
