import React from "react";
import {
  IResourceComponentsProps,
  BaseRecord,
  useTranslate,
  useUpdate,
} from "@refinedev/core";
import { useTable, List, EditButton } from "@refinedev/antd";
import { Table, Space, Popconfirm, Switch, Card, Radio, Checkbox } from "antd";
import { useNavigate } from "react-router-dom";
import { CheckOutlined } from "@ant-design/icons";

export const PackageList: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();
  const { tableProps } = useTable({
    syncWithLocation: true,
  });

  const { mutate } = useUpdate();

  return (
    <List
      title={t("package.title")}
      breadcrumb={null}
      createButtonProps={{ children: t("package.create_new") }}
    >
      <Card title="">
        <Table
          {...tableProps}
          rowKey="id"
          pagination={{
            ...tableProps.pagination,
            showSizeChanger: true,
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} ${t("common.of")} ${total}`,
          }}
        >
          <Table.Column dataIndex="id" title={t("package.id")} />
          <Table.Column dataIndex="name" title={t("package.name")} />
          <Table.Column
            dataIndex="maxManager"
            title={t("package.number_manager")}
            render={(v) => (v ? v : t("package.infinity"))}
          />
          <Table.Column
            dataIndex="maxUser"
            title={t("package.number_user")}
            render={(v) => (v ? v : t("package.infinity"))}
          />
          <Table.Column
            dataIndex="maxGroup"
            title={t("package.number_group")}
            render={(v) => (v ? v : t("package.infinity"))}
          />
          <Table.Column
            dataIndex="maxTemplate"
            title={t("package.number_template")}
            render={(v) => (v ? v : t("package.infinity"))}
          />
          <Table.Column
            dataIndex="maxLocation"
            title={t("package.number_location")}
            render={(v) => (v ? v : t("package.infinity"))}
          />
          <Table.Column
            dataIndex="isTemplateShared"
            title={t("package.allow_share_checklist")}
            render={(v) => <Checkbox checked={v} />}
          />
          <Table.Column
            width={100}
            title={t("common.action")}
            align="center"
            dataIndex="actions"
            render={(_, record: BaseRecord) => (
              <Space onClick={(e) => e.stopPropagation()}>
                <Popconfirm
                  title={t("common.confirm_change_state")}
                  description={t("common.desc_change_state")}
                  onConfirm={(e) => {
                    mutate({
                      id: record.id ?? "",
                      resource: "package",
                      values: {
                        id: record.id ?? "",
                        status:
                          record.status == "ACTIVE" ? "INACTIVE" : "ACTIVE",
                      },
                    });
                  }}
                  okText={t("common.confirm_ok")}
                  cancelText={t("common.confirm_close")}
                >
                  <Switch checked={record.status == "ACTIVE"} />
                </Popconfirm>
                <EditButton hideText size="small" recordItemId={record.id} />
              </Space>
            )}
          />
        </Table>
      </Card>
    </List>
  );
};
