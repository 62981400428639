import React from "react";
import { IResourceComponentsProps, useTranslate } from "@refinedev/core";
import { Breadcrumb, Create, useForm } from "@refinedev/antd";
import { Form, Input, Row, Col, InputNumber, Checkbox } from "antd";
import _ from "lodash";

export const PackageCreate: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();
  const { formProps, saveButtonProps, form } = useForm({
    onMutationError(res) {
      const errors = _.get(res, "response.data.messages") || [];
      if (errors.length) {
        const fields = errors.map((e: any) => ({
          name: e.key,
          errors: [e.msg],
        }));
        form.setFields(fields);
        form.scrollToField(fields[0].name, {
          behavior: "smooth",
          block: "center",
        });
      }
    },
  });
  const isEdit = !!formProps.initialValues?.id;

  return (
    <Create
      saveButtonProps={{
        ...saveButtonProps,
        children: isEdit ? t("package.save_edit") : t("package.save_create"),
      }}
      // footerButtonProps={{ hidden: true }}
      breadcrumb={null}
      title={isEdit ? t("package.title_edit") : t("package.title_create")}
      footerButtonProps={{
        style: { float: "left", paddingLeft: "calc(25% + 12px)" },
      }}
    >
      <Form {...formProps} layout="vertical">
        <Form.Item name={["id"]} hidden>
          <Input disabled />
        </Form.Item>
        <Form.Item
          label={"Status"}
          name={["status"]}
          initialValue="ACTIVE"
          hidden
          rules={[
            {
              required: true,
              message: t("common.required"),
            },
          ]}
        >
          <Input disabled />
        </Form.Item>

        <Row gutter={[12, 12]}>
          <Col span={6}></Col>
          <Col span={12}>
            <Row gutter={[12, 0]}>
              <Col span={24}>
                <Form.Item
                  label={t("package.name")}
                  name={["name"]}
                  rules={[
                    {
                      required: true,
                      message: t("common.required"),
                    },
                  ]}
                >
                  <Input maxLength={200} placeholder={t("package.name")} />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item label={t("package.note")} name={["description"]}>
                  <Input.TextArea
                    showCount
                    rows={5}
                    maxLength={200}
                    placeholder={t("package.note")}
                  />
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item
                  label={t("package.number_manager")}
                  name={["maxManager"]}
                >
                  <InputNumber
                    style={{ width: "100%" }}
                    placeholder={t("package.number_manager")}
                  />
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item label={t("package.number_user")} name={["maxUser"]}>
                  <InputNumber
                    style={{ width: "100%" }}
                    placeholder={t("package.number_user")}
                  />
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item
                  label={t("package.number_group")}
                  name={["maxGroup"]}
                >
                  <InputNumber
                    style={{ width: "100%" }}
                    placeholder={t("package.number_group")}
                  />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  label={t("package.number_template")}
                  name={["maxTemplate"]}
                >
                  <InputNumber
                    style={{ width: "100%" }}
                    placeholder={t("package.number_template")}
                  />
                </Form.Item>
              </Col>

              <Col span={12}>
                <Form.Item
                  label={t("package.number_location")}
                  name={["maxLocation"]}
                >
                  <InputNumber
                    style={{ width: "100%" }}
                    placeholder={t("package.number_location")}
                  />
                </Form.Item>
              </Col>
              <Col span={23}>
                <Form.Item
                  name={["isTemplateShared"]}
                  valuePropName="checked"
                  initialValue={false}
                >
                  <Checkbox style={{ marginLeft: "10px" }}>
                    {t("package.allow_share_checklist_desc")}
                  </Checkbox>
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Create>
  );
};
