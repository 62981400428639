import { useGetIdentity } from "@refinedev/core";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export default function FirstPage() {
  const { data: user } = useGetIdentity<any>();

  const role = user?.role?.name;
  const navigate = useNavigate();
  useEffect(() => {
    if (role == "supper_admin") {
      navigate("/company", { replace: true });
    }
    if (role == "company_admin") {
      navigate("/dashboard", { replace: true });
    }
    if (role == "company_manager") {
      navigate("/dashboard", { replace: true });
    }
  }, [role]);
  return <div />;
}
