import React from "react";
import {
  IResourceComponentsProps,
  BaseRecord,
  useTranslate,
} from "@refinedev/core";
import { useTable, List, EditButton, DateField } from "@refinedev/antd";
import { Table, Space, Card, Typography, Input, Avatar } from "antd";
import AvatarCustom from "components/avatar";

export const GroupUserList: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();
  const { tableProps, setFilters, filters, setCurrent } = useTable({
    syncWithLocation: true,
  });
  return (
    <List
      title={t("group.title")}
      createButtonProps={{ children: t("group.create_new") }}
    >
      <Card
        title={
          <Space>
            <Typography.Text>{t("group.label_search")}: </Typography.Text>
            <Input.Search
              allowClear
              placeholder={t("group.search")}
              onSearch={(e) => {
                setFilters([
                  {
                    field: "name",
                    operator: "contains",
                    value: e || undefined,
                  },
                ]);
                setCurrent(1);
              }}
            />
          </Space>
        }
      >
        <Table
          {...tableProps}
          rowKey="id"
          pagination={{
            ...tableProps.pagination,
            showSizeChanger: true,
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} ${t("common.of")} ${total}`,
          }}
        >
          <Table.Column
            dataIndex="users"
            title={t("group.images")}
            width={200}
            render={(v) => (
              <Avatar.Group>
                {v.slice(0, 3).map((a: any) => (
                  <AvatarCustom key={a.id} src={a.avatar}>
                    {a.fullName?.[0]}
                  </AvatarCustom>
                ))}
                {v.length > 3 && <AvatarCustom>+{v.length - 3}</AvatarCustom>}
              </Avatar.Group>
            )}
          />

          <Table.Column dataIndex={["name"]} title={t("group.name")} />

          <Table.Column
            dataIndex={["createdTime"]}
            title={t("common.created_at")}
            width="200px"
            sorter
            render={(value: any) => (
              <DateField value={value} format="DD/MM/YYYY HH:mm" />
            )}
          />

          <Table.Column
            title={t("common.action")}
            align="center"
            width="120px"
            dataIndex="actions"
            render={(_, record: BaseRecord) => (
              <Space>
                <EditButton hideText size="small" recordItemId={record.id} />
              </Space>
            )}
          />
        </Table>
      </Card>
    </List>
  );
};
