import React from "react";
import {
  ForgotPasswordPageProps,
  ForgotPasswordFormTypes,
  useRouterType,
  useLink,
} from "@refinedev/core";
import {
  Row,
  Col,
  Layout,
  Card,
  Typography,
  Form,
  Input,
  Button,
  LayoutProps,
  CardProps,
  FormProps,
  theme,
  Carousel,
} from "antd";
import {
  useTranslate,
  useRouterContext,
  useForgotPassword,
} from "@refinedev/core";

import {
  layoutStyles,
  containerStyles,
  titleStyles,
  headStyles,
  bodyStyles,
} from "../../styles";
import { Styled } from "pages/login";
import { MailOutlined } from "@ant-design/icons";

type ResetPassworProps = ForgotPasswordPageProps<
  LayoutProps,
  CardProps,
  FormProps
>;

const { Text, Title } = Typography;
const { useToken } = theme;

export const ForgotPassword = () => {
  const t = useTranslate();
  return (
    <Styled.Wrap>
      <Styled.Left>
        <Carousel autoplay>
          <Styled.Slide src="/images/carousel_1.jpg" />
          <Styled.Slide src="/images/carousel_2.jpg" />
          <Styled.Slide src="/images/carousel_3.jpg" />
          <Styled.Slide src="/images/carousel_4.jpg" />
          <Styled.Slide src="/images/carousel_5.jpg" />
        </Carousel>
      </Styled.Left>
      <Styled.Right>
        <Styled.Form>
          <Styled.Logo>
            <img src="/images/logo.svg" />
          </Styled.Logo>
          <Styled.Title>{t("forgot.title")}</Styled.Title>
          <FormSection />
        </Styled.Form>
      </Styled.Right>
    </Styled.Wrap>
  );
};

const FormSection = () => {
  const { token } = useToken();
  const [form] = Form.useForm<ForgotPasswordFormTypes>();
  const t = useTranslate();
  const routerType = useRouterType();
  const Link = useLink();
  const { Link: LegacyLink } = useRouterContext();

  const ActiveLink = routerType === "legacy" ? LegacyLink : Link;

  const { mutate: forgotPassword, isLoading } =
    useForgotPassword<ForgotPasswordFormTypes>();
  return (
    <Form<ForgotPasswordFormTypes>
      layout="vertical"
      form={form}
      onFinish={(values) => forgotPassword(values)}
      requiredMark={false}
    >
      <Form.Item
        name="email"
        rules={[
          { required: true, message: t("common.required") },
          {
            type: "email",
            message: t("common.wrong_email"),
          },
        ]}
      >
        <Input
          prefix={<MailOutlined style={{ color: "#1890ff" }} />}
          type="email"
          size="large"
          placeholder={t("common.email")}
        />
      </Form.Item>

      <Form.Item
        style={{
          marginTop: 24,
          marginBottom: 24,
        }}
      >
        <Button
          type="primary"
          style={{ borderRadius: 4 }}
          size="large"
          htmlType="submit"
          loading={isLoading}
          block
        >
          {t("forgot.button")}
        </Button>
      </Form.Item>
      <Text
        style={{
          fontSize: 14,
          marginLeft: "auto",
        }}
      >
        {t("forgot.you_have")}{" "}
        <ActiveLink
          style={{
            fontWeight: "bold",
            color: token.colorPrimaryTextHover,
          }}
          to="/login"
        >
          {t("forgot.back_to_login")}
        </ActiveLink>
      </Text>
    </Form>
  );
};
