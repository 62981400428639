import React, { useEffect, useState } from "react";
import {
  ForgotPasswordPageProps,
  ForgotPasswordFormTypes,
  useTranslate,
} from "@refinedev/core";
import {
  Row,
  Col,
  Layout,
  Card,
  Typography,
  Form,
  Input,
  Button,
  LayoutProps,
  CardProps,
  FormProps,
  theme,
} from "antd";
import { useUpdatePassword } from "@refinedev/core";

import {
  layoutStyles,
  containerStyles,
  titleStyles,
  headStyles,
  bodyStyles,
} from "../../styles";
import { Link, useSearchParams } from "react-router-dom";
import axios from "axios";
import { baseURL } from "authProvider";

type ResetPassworProps = ForgotPasswordPageProps<
  LayoutProps,
  CardProps,
  FormProps
>;

const { Text, Title } = Typography;

/**
 * **refine** has forgot password page form which is served on `/forgot-password` route when the `authProvider` configuration is provided.
 *
 * @see {@link https://refine.dev/docs/ui-frameworks/antd/components/antd-auth-page/#forgot-password} for more details.
 */
export const ResetPassword: React.FC<ResetPassworProps> = ({
  loginLink,
  wrapperProps,
  contentProps,
  renderContent,
  formProps,
  title,
}) => {
  const [form] = Form.useForm<ForgotPasswordFormTypes>();

  const { mutate: updatePassword, isLoading } = useUpdatePassword<any>();

  const [codeExpired, setCodeExpired] = useState(false);

  const t = useTranslate();
  let [searchParams, setSearchParams] = useSearchParams();

  const token = searchParams.get("token");

  useEffect(() => {
    if (token) {
      try {
        axios
          .post(baseURL + "/admin.identity.verify_otp", { token })
          .then((r) => {
            console.log(r.data);
          })
          .catch((e) => {
            console.log(e);
            setCodeExpired(true);
          });
      } catch (e) {}
    }
  }, [token]);

  const PageTitle =
    title === false ? null : (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginBottom: "32px",
          fontSize: "20px",
        }}
      >
        {title}
      </div>
    );

  const CardTitle = (
    <Title
      level={3}
      style={{
        ...titleStyles,
      }}
    >
      {t("passsword.reset")}
    </Title>
  );
  const CardContent = (
    <Card
      title={CardTitle}
      headStyle={headStyles}
      bodyStyle={bodyStyles}
      style={{
        ...containerStyles,
      }}
    >
      <Form<any>
        layout="vertical"
        form={form}
        onFinish={(values) => updatePassword(values)}
        requiredMark={false}
        {...formProps}
      >
        <Form.Item label="Token" hidden name={["token"]} initialValue={token}>
          <Input />
        </Form.Item>
        <Form.Item
          label={t("user.password")}
          name={["password"]}
          rules={[
            {
              required: true,
              message: t("common.required"),
            },
            {
              validator(rule, value, callback) {
                if (!value) Promise.resolve();
                if (value?.length < 6) {
                  return Promise.reject(t("common.password_620"));
                }
                if (!/^(?=.*[a-z])(?=.*[A-Z]).+$/.test(value)) {
                  return Promise.reject(t("common.password_character"));
                }
                if (
                  value?.indexOf(" ") > -1 ||
                  !/^[^\s\u00C0-\u024F]+$/.test(value)
                ) {
                  return Promise.reject(t("common.password_space"));
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <Input.Password
            maxLength={200}
            autoComplete="one-time-code"
            placeholder={t("user.password")}
          />
        </Form.Item>
        <Form.Item
          dependencies={["password"]}
          label={t("user.password_confirm")}
          name={["password_confirm"]}
          rules={[
            {
              required: true,
            },
            ({ getFieldValue }) => ({
              validator(rule, value) {
                if (!value) {
                  return Promise.resolve();
                }
                if (getFieldValue("password") != value) {
                  return Promise.reject(t("common.password_same"));
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <Input.Password
            autoComplete="one-time-code"
            placeholder={t("user.password_confirm")}
            maxLength={200}
          />
        </Form.Item>
        <Form.Item
          style={{
            marginTop: "24px",
            marginBottom: 0,
          }}
        >
          <Button
            type="primary"
            size="large"
            htmlType="submit"
            loading={isLoading}
            block
          >
            {t("passsword.finish")}
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );

  return (
    <Layout style={layoutStyles} {...(wrapperProps ?? {})}>
      <Row
        justify="center"
        align="middle"
        style={{
          height: "100vh",
        }}
      >
        <Col xs={22}>
          {PageTitle}
          {token && !codeExpired && CardContent}
          {codeExpired ? (
            <Card style={{ width: 400, margin: "auto" }}>
              <Typography.Text>{t("passsword.link_exp")}</Typography.Text>
              <br />
              <Link
                style={{
                  fontWeight: "bold",
                }}
                to="/login"
              >
                {t("forgot.back_to_login")}
              </Link>
            </Card>
          ) : (
            ""
          )}
        </Col>
      </Row>
    </Layout>
  );
};
