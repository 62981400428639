import axios, { AxiosInstance } from "axios";
import { stringify } from "query-string";
import { DataProvider, HttpError } from "@refinedev/core";
import { TOKEN_KEY, baseURL } from "authProvider";
import _ from "lodash";
import i18n from "i18n";
// import { axiosInstance, generateSort, generateFilter } from "./utils";

type MethodTypes = "get" | "delete" | "head" | "options";
type MethodTypesWithBody = "post" | "put" | "patch";

const axiosInstance = axios.create({
  baseURL: baseURL,
});

axiosInstance.interceptors.request.use(function (config) {
  const token = localStorage.getItem(TOKEN_KEY);
  config.headers.Authorization = "Bearer " + token;

  return config;
});

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    var msg = error.response?.data?.message;
    const customError: HttpError = {
      ...error,
      statusCode: "",
      // message: msg,
      message: i18n.t("ERROR." + msg),
    };

    const data: any = _.get(error, "response.data", {}) || {};

    if (data?.statusCode == 401) {
      localStorage.removeItem(TOKEN_KEY);
      window.location.href = "/login";
    }

    return Promise.reject(customError);
  }
);

export const dataProvider = (): Omit<
  Required<DataProvider>,
  "createMany" | "updateMany" | "deleteMany"
> => ({
  getList: async ({ resource, pagination, filters, sorters, meta }) => {
    let url = `/admin.${resource}.${meta?.type || "list"}`;

    const mapFilter: any = {
      contains: "cont",
      null: "isnull",
    };

    const { data, headers } = await axiosInstance.post(`${url}`, {
      filter: filters?.map((a) => ({
        ...a,
        operator: "$" + (mapFilter[a.operator] || a.operator),
      })),
      s: "",
      sort: sorters?.length
        ? sorters.map((a) => ({ ...a, order: a.order.toUpperCase() }))
        : [
            {
              field: "id",
              order: "DESC",
            },
          ],
      limit: pagination?.pageSize,
      offset: (pagination?.pageSize || 10) * ((pagination?.current || 1) - 1),

      ...(meta?.baseFilter || {}),
    });

    return {
      data: data[0],
      total: data[1],
    };
  },

  getMany: async ({ resource, ids, meta }) => {
    const { headers, method } = meta ?? {};
    const requestMethod = (method as MethodTypes) ?? "get";
    const token = localStorage.getItem(TOKEN_KEY);

    const { data } = await axiosInstance[requestMethod](
      `/admin.${resource}.list`,
      {}
    );

    return {
      data,
    };
  },

  create: async ({ resource, variables, meta }) => {
    let url = `/admin.${resource}.${meta?.type || "create"}`;

    if(meta?.type == "none") {
      url = `/admin.${resource}`;
    }
    if (url === "/admin.assign.assign") {
      url = "/admin.template.assign";
    }
    const token = localStorage.getItem(TOKEN_KEY);

    const { headers, method } = meta ?? {};

    const { data } = await axiosInstance.post(url, variables, {
      headers: { ...headers },
      responseType: meta?.responseType,
    });

    return {
      data,
    };
  },

  update: async ({ resource, id, variables, meta }) => {
    const url = `/admin.${resource}.${meta?.type || "update"}`;

    const { headers, method } = meta ?? {};
    const requestMethod = (method as MethodTypesWithBody) ?? "patch";

    const { data } = await axiosInstance.post(url, variables, {
      headers,
    });

    return {
      data,
    };
  },

  getOne: async ({ resource, id, meta }) => {
    if (!id) {
      return { data: null };
    }
    const url = `/admin.${resource}.${meta?.type || "detail"}`;

    let q: any = {
      id: id,
      userId: id,
      userWorkId: id,
      ...meta?.value,
    };
    if(meta?.key){
      q[meta.key] = id;
    }

    if (resource == "user") {
      q = { userId: id };
    }
    if (resource == "activity_user" && meta?.type == "checklist") {
      q = { userWorkId: id };
    }

    if (resource == "activity_location") {
      q = { locationId: id, ...meta?.value };
    }

    if (resource == "report_template") {
      q = { ...meta?.value };
    }

    const { data } = await axiosInstance.post(url, q, {
      responseType: meta?.responseType,
    });

    return {
      data,
    };
  },

  deleteOne: async ({ resource, id, variables, meta }) => {
    const url = `/admin.${resource}.delete`;

    const { headers, method } = meta ?? {};
    const requestMethod = (method as MethodTypesWithBody) ?? "delete";

    const { data } = await axiosInstance.post(url, {
      id: id,
    });

    return {
      data,
    };
  },

  getApiUrl: () => {
    return "";
  },

  custom: async ({
    url,
    method,
    filters,
    sorters,
    payload,
    query,
    headers,
  }) => {
    let requestUrl = `${url}?`;

    // if (sorters) {
    //   const generatedSort = generateSort(sorters);
    //   if (generatedSort) {
    //     const { _sort, _order } = generatedSort;
    //     const sortQuery = {
    //       _sort: _sort.join(","),
    //       _order: _order.join(","),
    //     };
    //     requestUrl = `${requestUrl}&${stringify(sortQuery)}`;
    //   }
    // }

    // if (filters) {
    //   const filterQuery = generateFilter(filters);
    //   requestUrl = `${requestUrl}&${stringify(filterQuery)}`;
    // }

    // if (query) {
    //   requestUrl = `${requestUrl}&${stringify(query)}`;
    // }

    // if (headers) {
    //   httpClient.defaults.headers = {
    //     ...httpClient.defaults.headers,
    //     ...headers,
    //   };
    // }

    let axiosResponse;
    switch (method) {
      case "put":
      case "post":
      case "patch":
        axiosResponse = await axiosInstance[method](url, payload);
        break;
      case "delete":
        axiosResponse = await axiosInstance.delete(url, {
          data: payload,
        });
        break;
      default:
        axiosResponse = await axiosInstance.get(requestUrl);
        break;
    }

    const { data } = axiosResponse;

    return Promise.resolve({ data });
  },
});
