import React from "react";
import {
  IResourceComponentsProps,
  useShow,
  useTranslate,
} from "@refinedev/core";
import { Show, useSelect } from "@refinedev/antd";
import {
  Form,
  Input,
  DatePicker,
  Row,
  Col,
  Select,
  TimePicker,
  InputNumber,
  Checkbox,
} from "antd";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import SelectCustom from "components/select";
import { MapView } from "components/map";
dayjs.extend(utc);

export const AssignShow: React.FC<IResourceComponentsProps> = () => {
  const { queryResult } = useShow();
  const t = useTranslate();

  // const { selectProps: templateSelectProps } = useSelect({
  //   resource: "template",
  //   optionLabel: "name",
  //   pagination: {
  //     pageSize: 10000,
  //   },
  //   filters: [
  //     {
  //       value: "ACTIVE",
  //       operator: "eq",
  //       field: "status",
  //     },
  //   ],
  // });
  const { selectProps: userSelectProps } = useSelect({
    resource: "user",
    optionLabel: "fullName",
    optionValue: "userId",
    pagination: {
      pageSize: 10000,
    },
    filters: [
      {
        value: "ACTIVE",
        operator: "eq",
        field: "status",
      },
    ],
  });
  const { selectProps: groupSelectProps } = useSelect({
    resource: "group_user",
    optionLabel: "name",
    pagination: {
      pageSize: 10000,
    },
  });

  // const checkListType = Form.useWatch("checkListType", formProps.form);
  // const qrCode = Form.useWatch("qrCode", formProps.form);

  // const { selectProps: locationSelectProps } = useSelect({
  //   resource: "location",
  //   optionLabel: "name",
  //   filters: [
  //     {
  //       field: "status",
  //       value: "ACTIVE",
  //       operator: "eq",
  //     },
  //   ],
  // });

  // const userIds = Form.useWatch("userIds", formProps.form);
  // const groupIds = Form.useWatch("groupIds", formProps.form);

  const record = queryResult.data?.data as any;
  console.log(record);
  return (
    <Show breadcrumb={null} headerButtons={<></>} title={t("assign.detail")}>
      {record && (
        <Form
          disabled
          layout="vertical"
          initialValues={{
            ...record,
            date: [
              record?.startTime
                ? dayjs(record?.startTime.slice(0, 10))
                : dayjs().startOf("M"),
              record?.endTime
                ? dayjs(record?.endTime.slice(0, 10))
                : dayjs().endOf("M"),
            ],
            time: [
              dayjs(record?.timeHourStart || "06:00:00", "HH:mm:ss"),
              dayjs(record?.timeHourEnd || "18:00:00", "HH:mm:ss"),
            ],
            userIds: record?.userIds || [],
            groupIds: (record?.groupIds || []).map((a: any) => a * 1),
            locationId: record?.location?.id,
          }}
        >
          <Form.Item name={["id"]} hidden>
            <Input disabled />
          </Form.Item>

          <Row gutter={12}>
            <Col span={6} />
            <Col span={12}>
              <Row gutter={12}>
                <Col span={16}>
                  <Form.Item label={t("assign.name")} name={["name"]}>
                    <Input maxLength={200} />
                  </Form.Item>
                </Col>
                <Col span={8}>
                  <Form.Item label={t("assign.code")} name={["assignCode"]}>
                    <Input maxLength={15} />
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item label={t("assign.template")} name={["templateId"]}>
                    <Select>
                      <Select.Option value={record.templates?.id}>
                        {record.templates?.name}
                      </Select.Option>
                    </Select>
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item label={t("assign.user")} name={["userIds"]}>
                    <SelectCustom {...userSelectProps} mode="multiple" />
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item label={t("assign.group")} name={["groupIds"]}>
                    <SelectCustom {...groupSelectProps} mode="multiple" />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item
                    name={["checkListType"]}
                    label={t("assign.type")}
                    initialValue={"DAILY"}
                  >
                    <Select>
                      <Select.Option value="DAILY">
                        {t("common.assign_type_DAILY")}
                      </Select.Option>
                      <Select.Option value="INCURRED">
                        {t("common.assign_type_INCURRED")}
                      </Select.Option>
                    </Select>
                  </Form.Item>
                </Col>
                {record?.checkListType == "DAILY" && (
                  <Col span={12}>
                    <Form.Item
                      name={["dayOfWeek"]}
                      initialValue={["1", "2", "3", "4", "5"]}
                      label={t("assign.repeat")}
                    >
                      <Select mode="multiple">
                        <Select.Option value="1">
                          {t("common.monday")}
                        </Select.Option>
                        <Select.Option value="2">
                          {t("common.tuesday")}
                        </Select.Option>
                        <Select.Option value="3">
                          {t("common.wednesday")}
                        </Select.Option>
                        <Select.Option value="4">
                          {t("common.thursday")}
                        </Select.Option>
                        <Select.Option value="5">
                          {t("common.friday")}
                        </Select.Option>
                        <Select.Option value="6">
                          {t("common.satuday")}
                        </Select.Option>
                        <Select.Option value="0">
                          {t("common.sunday")}
                        </Select.Option>
                      </Select>
                    </Form.Item>
                  </Col>
                )}

                {record?.checkListType == "INCURRED" && (
                  <Col span={12}>
                    <Form.Item name={["maxWork"]} label={t("assign.kpi")}>
                      <InputNumber style={{ width: "100%" }} />
                    </Form.Item>
                  </Col>
                )}

                <Col span={12}>
                  <Form.Item label={t("assign.date_range")} name={["date"]}>
                    <DatePicker.RangePicker
                      style={{ width: "100%" }}
                      placeholder={[
                        t("common.select_start"),
                        t("common.select_end"),
                      ]}
                    />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item label={t("assign.time_range")} name={["time"]}>
                    <TimePicker.RangePicker
                      placeholder={[
                        t("common.select_time_start"),
                        t("common.select_time_end"),
                      ]}
                      style={{ width: "100%" }}
                      format="HH:mm:ss"
                    />
                  </Form.Item>
                </Col>

                <Col span={24}>
                  <Form.Item
                    label={t("assign.random")}
                    name={["randomQuestions"]}
                  >
                    <InputNumber style={{ width: "100%" }} />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item valuePropName="checked" name={["isSelectImage"]}>
                    <Checkbox title="Requried qrcode">
                      {t("assign.select_photos")}
                    </Checkbox>
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item valuePropName="checked" name={["qrCode"]}>
                    <Checkbox title="Requried qrcode">
                      {t("assign.required_location")}
                    </Checkbox>
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    valuePropName="checked"
                    name={["isSeeInstruction"]}
                  >
                    <Checkbox title="allow_view_guide">
                      {t("assign.allow_view_guide")}
                    </Checkbox>
                  </Form.Item>
                </Col>

                {record?.qrCode && (
                  <>
                    <Col span={24}>
                      <Form.Item
                        name={["locationId"]}
                        label={t("assign.location")}
                      >
                        <Select>
                          <Select.Option value={record.location?.id}>
                            {record.location?.name}
                          </Select.Option>
                        </Select>
                      </Form.Item>
                    </Col>
                    {record?.location && (
                      <Col span={24}>
                        <MapView
                          center={record?.location}
                          key={record?.location?.id}
                          locations={[record?.location]}
                        />
                      </Col>
                    )}
                  </>
                )}
              </Row>
            </Col>
          </Row>
        </Form>
      )}
    </Show>
  );
};
