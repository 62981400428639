import { useNotification, useTranslate } from "@refinedev/core";
import { Image, Modal, QRCode } from "antd";
import { toJpeg } from "html-to-image";
import { useState } from "react";

export const QrLocation = ({ location }: { location: any }) => {
  const [qrView, setQr] = useState<any>({});
  const { open } = useNotification();
  const t = useTranslate();
  function doDownload(url: string, fileName: string) {
    const a = document.createElement("a");
    a.download = fileName;
    a.href = url;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    open?.({
      message: t("common.download_success"),
      type: "success",
    });
  }
  const downloadCanvasQRCode = () => {
    const node = document.getElementById("myqrcode") as any;
    toJpeg(node).then((r) => {
      console.log(r);
      doDownload(r, qrView?.qrCode);
    });
  };
  return (
    <>
      <Modal
        onCancel={() => setQr("")}
        open={!!qrView?.qrCode}
        width={350}
        centered
        destroyOnClose
        onOk={() => {
          downloadCanvasQRCode();
        }}
        okText={t("common.download")}
      >
        <div
          id="myqrcode"
          style={{
            width: 300,
            background: "white",
            border: "1px solid black",
            textAlign: "center",
            padding: 24,
          }}
        >
          <QRCode
            type="canvas"
            value={qrView?.qrCode}
            bgColor="#fff"
            size={250}
            style={{ display: "inline-block" }}
          />
          <p style={{ margin: 0, fontSize: 18, fontWeight: "bold" }}>
            {qrView?.qrCode}
          </p>
          <p style={{ margin: 0 }}>{qrView?.name}</p>
          <p style={{ margin: 0 }}>{qrView?.address}</p>
        </div>
      </Modal>
      <div onClick={() => setQr(location)} style={{ cursor: "pointer" }}>
        <Image
          width={32}
          height={32}
          src={
            "https://api.qrserver.com/v1/create-qr-code/?size=500x500&data=" +
            location?.qrCode
          }
          preview={false}
        />
      </div>
    </>
  );
};
