import Icon, { CalendarOutlined, CarryOutOutlined, CheckCircleOutlined, CopyOutlined, DeleteOutlined, DownloadOutlined, EditOutlined, EnvironmentOutlined, LinkOutlined, UserOutlined } from "@ant-design/icons";
import { DateField, useModalForm } from "@refinedev/antd";
import { useCreate, useOne, useTranslate } from "@refinedev/core";
import { Avatar, Button, Col, Drawer, Form, Input, Modal, notification, Popconfirm, Row, Space, Table, Tabs, Tag, Typography } from "antd";
import { basePDFURL, baseURL, TOKEN_KEY } from "authProvider";
import AvatarCustom from "components/avatar";
import ViewImage from "components/view_image";
import dayjs from "dayjs";
import _ from "lodash";
import { useState } from "react";
import { Link } from "react-router-dom";

export const ResultCheckList = ({
    workId,
    workCode,
  }: {
    workId: string;
    workCode: string;
  }) => {
    const [workOrderId, setWorkOrderId] = useState("");
    const [isDownload, setIsDownload] = useState(false);
  
    const { data, refetch } = useOne({
      resource: "activity_user",
      meta: {
        type: "checklist",
      },
      id: workOrderId,
    });
  
    const checklist = data?.data as any;
  
    const { data: childrenDataRaw, refetch: refetchChildren } = useOne({
      resource: "activity_user",
      meta: {
        type: "checklist_children",
        baseFilter: {
          userWorkId: workOrderId,
        },
      },
      id: workOrderId,
    });
  
    const childrenData = childrenDataRaw?.data as any;
  
    const t = useTranslate();
    const { mutate } = useCreate();
    const handleDownload = async (
      workId: string,
      pdfFileName: string,
      exportName?: string
    ) => {
      let url = `${basePDFURL}&id=${workId}&token=${localStorage.getItem(
        TOKEN_KEY
      )}`;
  
      if (pdfFileName !== "" && pdfFileName != undefined) {
        url = `${baseURL}/view-file/${pdfFileName}?token=${localStorage.getItem(
          TOKEN_KEY
        )}`;
      }
      const maxRetry = 3;
  
      for (let attempt = 1; attempt <= maxRetry; attempt++) {
        try {
          setIsDownload(true);
          const response = await fetch(url, { method: "GET" });
  
          if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
          }
  
          const blob = await response.blob();
  
          // Create a link element and trigger a download
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = `${exportName || workId}.pdf`;
  
          // Append the link to the body and programmatically click it
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
  
          setIsDownload(false);
          return; // Exit the function after successful download
        } catch (e) {
          setIsDownload(false);
          console.log(e);
          if (attempt === maxRetry) {
            notification.error({
              message: t("activity.download_pdf_failed"),
            });
          }
        }
      }
    };
  
    let questions = checklist?.assignTemplate?.questions;
  
    if (checklist?.status == "DONE") {
      questions = questions.filter((q: any) =>
        checklist?.anwsers?.find((a: any) => a.questId == q.id)
      );
      questions = _.orderBy(questions, ["sort"], ["asc"]);
    }
  
    const [guideText, setGuideText] = useState("");
  
    const { show, modalProps, formProps } = useModalForm({
      warnWhenUnsavedChanges: false,
      resource: "user_work",
      action: "create",
      meta: {
        type: "add_parent",
      },
      onMutationSuccess: () => {
        refetch();
      },
    });

    const token = localStorage.getItem( TOKEN_KEY    );
  
    return (
      <>
        <Space size="small">
          <Typography.Link
            onClick={() => {
              setWorkOrderId(workId);
            }}
          >
            {workCode}
          </Typography.Link>
          <Button size="small" type="text"
            onClick={()=>{
              navigator.clipboard.writeText(workCode);
              notification.success({
                message: t("common.copy_success"),
              });
            }}
          >
            <CopyOutlined />
          </Button>
        </Space>
  
        <Modal
          width={1200}
          open={!!guideText}
          title={t("question.guide")}
          onOk={() => setGuideText("")}
          onCancel={() => setGuideText("")}
          okText={t("common.ok")}
          cancelButtonProps={{ hidden: true }}
        >
          <div dangerouslySetInnerHTML={{ __html: guideText }}></div>
        </Modal>
  
        <Drawer
          title={
            <Space>
              <Typography.Title level={5} style={{ margin: 0 }}>
                {checklist?.workCode}
              </Typography.Title>
              {checklist?.result != "MISS" && (
                <Button
                  loading={isDownload}
                  onClick={async () => {
                    await handleDownload(
                      workId,
                      checklist?.pdfFile,
                      checklist?.workCode
                    );
                  }}
                  style={{ float: "right" }}
                  color="blue"
                  icon={<DownloadOutlined />}
                >
                  {t("activity.read_report")}
                </Button>
              )}
            </Space>
          }
          placement="right"
          width={900}
          onClose={() => {
            setWorkOrderId("");
          }}
          open={!!workOrderId}
        >
          <Typography.Title level={3}>
            {checklist?.assignTemplate?.name}
          </Typography.Title>
          <Space style={{ width: "100%" }} direction="vertical">
            <Row gutter={[12, 12]} align="middle">
              <Col>
                <CalendarOutlined />
              </Col>
              <Col span={8}>
                <Typography.Text>{t("activity.working_time")}</Typography.Text>
              </Col>
              <Col span={12}>
                {checklist?.assignTemplate?.checkListType == "INCURRED" ? (
                  "-"
                ) : (
                  <Typography.Text>
                    {dayjs(checklist?.startTime).utc().format("DD/MM/YYYY HH:mm")}
                    -{dayjs(checklist?.endTime).utc().format("HH:mm")}
                  </Typography.Text>
                )}
              </Col>
            </Row>
  
            <Row gutter={[12, 12]} align="middle">
              <Col>
                <CalendarOutlined />
              </Col>
              <Col span={8}>
                <Typography.Text>{t("activity.working_finish")}</Typography.Text>
              </Col>
              <Col span={12}>
                <Typography.Text>
                  {checklist?.submitTime
                    ? dayjs(checklist?.submitTime)
                        .utc()
                        .format("DD/MM/YYYY HH:mm")
                    : "-"}
                </Typography.Text>
              </Col>
            </Row>
  
            <Row gutter={[12, 12]} align="middle">
              <Col>
                <CarryOutOutlined />
              </Col>
              <Col span={8}>
                <Typography.Text>{t("activity.result")}</Typography.Text>
              </Col>
              <Col span={12}>
                {checklist?.result == "MISS" && <Tag color="red">MISS</Tag>}
                {checklist?.result == "OK" && <Tag color="green">OK</Tag>}
                {checklist?.result == "NG" && <Tag color="blue">NG</Tag>}
              </Col>
            </Row>
  
            <Row gutter={[12, 12]} align="middle">
              <Col>
                <UserOutlined />
              </Col>
              <Col span={8}>
                <Typography.Text>{t("activity.user_work")}</Typography.Text>
              </Col>
              <Col span={12}>
                <Space>
                  {checklist?.user.avatar ? (
                    <AvatarCustom size={18} src={checklist?.user.avatar} />
                  ) : (
                    <Avatar size={18} style={{ fontSize: 12, lineHeight: 12 }}>
                      {checklist?.user.fullName?.[0]}
                    </Avatar>
                  )}
  
                  <Typography.Text>{checklist?.user?.fullName}</Typography.Text>
                </Space>
              </Col>
            </Row>
  
            <Row gutter={[12, 12]} align="middle">
              <Col>
                <EnvironmentOutlined />
              </Col>
              <Col span={8}>
                <Typography.Text>{t("activity.location_need")}</Typography.Text>
              </Col>
              <Col span={12}>
                <Typography.Text>
                  {checklist?.assignTemplate?.location?.name}
                </Typography.Text>
              </Col>
            </Row>
  
            <Row gutter={[12, 12]} align="middle">
              <Col>
                <EnvironmentOutlined />
              </Col>
              <Col span={8}>
                <Typography.Text>{t("activity.location_work")}</Typography.Text>
              </Col>
              
              {checklist?.status == "DONE" ? (
                <Col span={12}>
                    <Typography.Text>
                    {checklist?.location?.name}
                    </Typography.Text>
                    <br />
                  {_.toNumber(checklist?.lat) && _.toNumber(checklist?.long) ? (
                    <Link
                      to={`https://www.google.com/maps/?q=loc:${checklist?.lat},${checklist?.long}`}
                      target="_blank"
                    >
                      {t("activity.location_google_link")}
                    </Link>
                  ) : (
                    <span>
                      <i>{t("activity.location_empty")}</i>
                    </span>
                  )}
                </Col>
              ) : (
                <Col span={12} />
              )}
            </Row>
            <>
              <Row gutter={[12, 12]}>
                <Col>
                  <CalendarOutlined />
                </Col>
                <Col span={8}>
                  <Typography.Text>{t("activity.connect_date")}</Typography.Text>
                </Col>
                <Col span={12}>
                  <Typography.Text>
                    {checklist?.linkedTime
                      ? dayjs(checklist?.linkedTime).utc().format("YYYY-MM-DD HH:mm")
                      : "-"}
                  </Typography.Text>
                </Col>
              </Row>
              <Row gutter={[12, 12]} align="middle">
                <Col>
                  <LinkOutlined />
                </Col>
                <Col span={8}>
                  <Typography.Text>{t("activity.parent_name")}</Typography.Text>
                </Col>
                <Col span={12}>
                  <Typography.Text
                    style={{ cursor: "pointer", color: "#1677ff" }}
                    onClick={async () => {
                      await handleDownload(
                        checklist?.parentId,
                        "",
                        checklist?.parentCode
                      );
                    }}
                  >
                    {checklist?.parentCode}
                  </Typography.Text>
                </Col>
                <Col>
                  <Button
                    size="small"
                    onClick={() => {
                      show();
                      formProps.form?.setFieldValue(
                        "workCode",
                        checklist?.workCode
                      );
                    }}
                    icon={<EditOutlined />}
                  />{" "}
                  {checklist?.parentCode && (
                    <Button
                      danger
                      size="small"
                      onClick={() => {
                        Modal.confirm({
                          title: t("connect.delete_confirm"),
                          content: t("connect.delete_confirm_desc"),
                          okText: t("common.ok"),
                          cancelText: t("common.close"),
                          onOk: async () => {
                            mutate(
                              {
                                resource: "user_work",
                                values: {
                                  workCode: checklist?.workCode,
                                },
                                meta: {
                                  type: "remove_parent",
                                },
                              },
                              {
                                onSuccess: () => {
                                  refetch();
                                },
                              }
                            );
                          },
                        });
                      }}
                      icon={<DeleteOutlined />}
                    />
                  )}
                </Col>
              </Row>
            </>
          </Space>
  
          <br />
          <br />
  
          <Typography.Title level={5}>
            {t("activity.question_list")}
          </Typography.Title>
  
          <hr
            style={{
              margin: "12px -24px",
              borderTop: " 1px solid #f7f7f7",
            }}
          />
  
          <Tabs>
            <Tabs.TabPane key="1" tab={t("activity.question_list")}>
              <Table dataSource={questions} size="small"
                pagination={{
                  showSizeChanger: true,
                }}
              >
                <Table.Column
                  title={t("activity.question_name")}
                  dataIndex="name"
                  render={(v, r: any) => {
                    return (
                      <div>
                        <div>{v}</div>
                        <p style={{whiteSpace: 'pre-line', fontSize: 12}}>
                          {r.description}
                        </p>
                      </div>
                    );
                  }}
                />
                <Table.Column
                  title={t("question.guide")}
                  dataIndex="guide"
                  render={(v) => {
                    if (!v) return;
                    return (
                      <a
                        className="ant-typography "
                        onClick={() => setGuideText(v)}
                      >
                        {t("question.guide_detail")}
                      </a>
                    );
                  }}
                />
                <Table.Column
                  title={t("activity.question_type")}
                  dataIndex="typeQuestion"
                  width={150}
                  render={(v) => {
                    if (v == "NORMAL")
                      return <Tag color="blue">{t("question.type_NORMAL")}</Tag>;
                    if (v == "CAPTURE")
                      return (
                        <Tag color="purple">{t("question.type_CAPTURE")}</Tag>
                      );
                    if (v == "QR_CODE")
                      return (
                        <Tag color="volcano">{t("question.type_QRCODE")}</Tag>
                      );
                    if (v == "TEXT_INPUT")
                      return (
                        <Tag color="geekblue">{t("question.type_TEXT")}</Tag>
                      );
                    if (v == "RANGES")
                      return <Tag color="gold">{t("question.type_RANGES")}</Tag>;
                    if (v == "OPTIONS")
                      return (
                        <Tag color="green">{t("question.type_OPTIONS")}</Tag>
                      );
                    if (v == "CHECKBOXS")
                      return (
                        <Tag color="processing">
                          {t("question.type_CHECKBOXS")}
                        </Tag>
                      );
                    if (v == "SIGN")
                      return <Tag color="orange">{t("question.type_SIGN")}</Tag>;
                  }}
                />
                <Table.Column
                  title={t("activity.question_anwser")}
                  dataIndex="id"
                  width={200}
                  render={(v: string, r: any) => {
                    const anwser = checklist?.anwsers?.find(
                      (a: any) => a.questId == v
                    );
  
                    if (!anwser) {
                      return "-";
                    }
                    if (r.typeQuestion == "CHECKBOXS") {
                      return (
                        <div>
                          <ul>
                            {anwser?.dataQuestion.map((a: any) => (
                              <li key={a.value}>{a.value}</li>
                            ))}
                          </ul>
                        </div>
                      );
                    }
                    
                    if (r.typeQuestion == "RANGES") {
                      var min = _.get(v, "dataQuestion[0].value");
                      var max = _.get(v, "dataQuestion[1].value");
                      var result = _.get(anwser, "dataQuestion[0].value");
  
                      if (min && max && (result < min || result > max)) {
                        return <div style={{ color: "red" }}>{result}</div>;
                      }
  
                      return <div>{result}</div>;
                    }
                    return (
                      <div
                        style={{
                          whiteSpace: "pre-line",
                        }}
                      >
                        {anwser?.dataQuestion
                          .map((a: any) => a.value)
                          .join(" | ")}
                      </div>
                    );
                  }}
                />
                <Table.Column
                  title={t("activity.question_image")}
                  dataIndex="id"
                  width={200}
                  render={(v, r: any) => {
                    const anwser = checklist?.anwsers?.find(
                      (a: any) => a.questId == v
                    );
                    if (!anwser) {
                      return "-";
                    }
                    if(r.typeQuestion == "TEXT_INPUT") {
                      return (
                        <div>
                          {anwser.links?.map((a: any) => (
                            <div key={a.link}>
                              <a target="_blank" href={`${baseURL}/view-file/${a.link}?token=${token}`}>{a.link}</a>
                          </div>
                          ))}
                        </div>
                      );
                    }
                    return (
                      <Space>
                        {anwser.links?.map((a: any) => (
                          <ViewImage height={60} value={a.link} />
                        ))}
                      </Space>
                    );
                  }}
                />
              </Table>
            </Tabs.TabPane>
            <Tabs.TabPane key="2" tab={t("connect.checklist_child")}>
              <Table dataSource={childrenData} size="small">
                <Table.Column
                  title={t("connect.workCode")}
                  dataIndex="workCode"
                  render={(v, c: any) => (
                    <Typography.Text
                      style={{ cursor: "pointer", color: "#1677ff" }}
                      onClick={async () => {
                        await handleDownload(c?.id, c?.pdfFile, c?.workCode);
                      }}
                    >
                      {v}
                    </Typography.Text>
                  )}
                />
                <Table.Column
                  title={t("connect.name")}
                  dataIndex={["assignTemplate", "templates", "name"]}
                />
                <Table.Column title={t("connect.userId")} dataIndex={['user', 'fullName']} />
                <Table.Column title={t("connect.result")} dataIndex="result" />
                <Table.Column
                  title={t("connect.dateConnect")}
                  dataIndex="linkedTime"
                  render={(v) => {
                    return  dayjs(v).utc().format("YYYY-MM-DD HH:mm");
                  }}
                />
                <Table.Column
                  width={60}
                  render={(r) => {
                    return (
                      <Popconfirm
                        title={t("connect.delete_confirm")}
                        description={t("connect.delete_confirm_desc")}
                        onConfirm={async () => {
                          mutate(
                            {
                              resource: "user_work",
                              values: {
                                workCode: r?.workCode,
                              },
                              meta: {
                                type: "remove_parent",
                              },
                            },
                            {
                              onSuccess: () => {
                                refetchChildren();
                              },
                            }
                          );
                        }}
                        okText={t("common.ok")}
                        cancelText={t("common.close")}
                      >
                        <Button danger size="small" icon={<DeleteOutlined />} />
                      </Popconfirm>
                    );
                  }}
                />
              </Table>
            </Tabs.TabPane>
          </Tabs>
          <Modal
            {...modalProps}
            title={t("activity.update_connect_work")}
            okText={t("common.ok")}
            cancelText={t("common.close")}
            width={500}
          >
            <Form {...formProps} layout="vertical">
              <Form.Item
                name="parentWorkCode"
                label={t("activity.parent_name")}
                rules={[{ required: true }]}
              >
                <Input placeholder={t("activity.parent_name")} />
              </Form.Item>
  
              <Form.Item hidden name="workCode" rules={[{ required: true }]}>
                <Input />
              </Form.Item>
            </Form>
          </Modal>
        </Drawer>
      </>
    );
  };
  