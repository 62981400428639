import React, { useEffect } from "react";
import {
  IResourceComponentsProps,
  BaseRecord,
  useTranslate,
  useGetIdentity,
} from "@refinedev/core";
import {
  useTable,
  List,
  ShowButton,
  useSelect,
  ImageField,
  DateField,
} from "@refinedev/antd";
import {
  Table,
  Space,
  Col,
  Button,
  Card,
  Row,
  DatePicker,
  Select,
  Typography,
  Input,
  TimeRangePickerProps,
  Avatar,
  Tabs,
  QRCode,
  Image,
  Modal,
  Tag,
} from "antd";

import { toJpeg } from "html-to-image";
import { IUser } from "types";
import dayjs, { Dayjs } from "dayjs";
import { useState } from "react";
import ViewImage from "components/view_image";
import { Link, useSearchParams } from "react-router-dom";
import { QrLocation } from "components/qrview";
import _, { filter } from "lodash";

export const ActivityLocationList: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();
  const [baseFilter, setBaseFilter] = useState<any>({});
  const [searchParams] = useSearchParams();

  const startDate = searchParams.get("startDate");
  const initialDate = startDate ? dayjs(startDate) : dayjs();
  const startDateFilter = startDate
    ? initialDate
    : initialDate.startOf("month");
  const endDateFilter = startDate ? initialDate : initialDate.endOf("month");

  const initialValues = {
    date: [startDateFilter, endDateFilter] as [Dayjs | null, Dayjs | null],
  };

  const managerId = localStorage.getItem("MANAGER_ID") || "";
  useEffect(() => {
    if (managerId != "") {
      setBaseFilter({ managerId });
    }
  }, [managerId]);

  useEffect(() => {
    setBaseFilter((c: any) => ({
      ...c,
      startDate: startDateFilter.format("YYYY-MM-DD"),
      endDate: endDateFilter.format("YYYY-MM-DD"),
    }));
  }, []);

  console.log(baseFilter);
  const { tableProps } = useTable({
    syncWithLocation: true,
    meta: {
      baseFilter: baseFilter,
    },
  });

  const {
    tableProps: tablePropsLocation,
    setFilters,
    setCurrent,
  } = useTable({
    resource: "location",
    syncWithLocation: true,
    pagination: {
      pageSize: 10000,
    },
    meta: {
      baseFilter: baseFilter,
    },
  });

  const rangePresets: TimeRangePickerProps["presets"] = [
    { label: t("common.this_month"), value: [dayjs().set("D", 1), dayjs()] },
    {
      label: t("common.last_month"),
      value: [dayjs().set("D", 1).subtract(1, "M"), dayjs().set("D", 1)],
    },
    {
      label: t("common.this_year"),
      value: [dayjs().set("M", 0).set("D", 1), dayjs()],
    },
  ];

  const getDetailUrl = (locationId: string, startDate: string | null) => {
    if (startDate) {
      return (
        "/activity_location/show/" + locationId + "?startDate=" + startDate
      );
    }
    return "/activity_location/show/" + locationId;
  };
  const { selectProps: selectPropsUser } = useSelect({
    resource: "user",
    optionLabel: "fullName",
    optionValue: "userId",
    pagination: {
      pageSize: 10000,
    },
    filters: [
      {
        value: "ACTIVE",
        operator: "eq",
        field: "status",
      },
    ],
  });

  const [currentTab, setCurrentTab] = useState("1");

  const [filterName, setFilterName] = useState("");
  const [filterUser, setFilterUser] = useState("");
  const [filterTag, setFilterTag] = useState("");

  const onSearch = () => {
    const f = [];
    if (filterName) {
      f.push({
        field: "name",
        operator: "contains",
        value: filterName,
      });
    }
    if (filterUser) {
      f.push({
        field: "userInCharge",
        operator: "eq",
        value: filterUser,
      });
    }
    if (filterUser == "NO_USER") {
      f.push({
        field: "userInCharge",
        operator: "isnull",
        value: "",
      });
    }
    if (filterTag) {
      f.push({
        field: "tags",
        operator: "contains",
        value: filterTag,
      });
    }
    setFilters(f as any, "replace");
    setCurrent(1);
  };

  useEffect(() => {
    onSearch();
  }, [filterUser, filterTag, filterName]);

  return (
    <List title={t("activity.title_location")}>
      <Card
        title={
          <Space>
            <Typography.Text>
              {t("activity.label_search_location")}:
            </Typography.Text>
            <Input.Search
              allowClear
              placeholder={t("group.search")}
              onSearch={(e) => {
                setBaseFilter((c: any) => ({
                  ...c,
                  locationName: e || undefined,
                }));
                setFilterName(e);
              }}
            />
          </Space>
        }
        extra={
          <Space size="small">
            <div style={{ width: 200 }}>
              <Select
                style={{ width: "100%" }}
                {...selectPropsUser}
                placeholder={t("location.select_user_in_charge")}
                allowClear
                options={[
                  {
                    value: "NO_USER",
                    label: t("location.no_user_in_charge"),
                  },
                  ...(selectPropsUser.options || []),
                ]}
                onChange={(e: any) => {
                  setFilterUser(e);
                  if (e == "NO_USER") {
                    setBaseFilter((c: any) => ({
                      ...c,
                      userInCharge: undefined,
                    }));
                  } else {
                    setBaseFilter((c: any) => ({
                      ...c,
                      userInCharge: e || undefined,
                    }));
                  }
                }}
              />
            </div>
            <div style={{ width: 200 }}>
              <Input
                placeholder={t("location.tags")}
                onChange={(e) => {
                  const s = () => {
                    setFilterTag(e.target.value);
                    setBaseFilter((c: any) => ({
                      ...c,
                      tags: e.target.value || undefined,
                    }));
                  };
                  _.debounce(s, 1000)();
                }}
              />
            </div>
            {currentTab != "2" && (
              <DatePicker.RangePicker
                placeholder={[t("common.select_start"), t("common.select_end")]}
                defaultValue={initialValues.date}
                presets={rangePresets}
                onChange={(date) => {
                  setBaseFilter((c: any) => ({
                    ...c,
                    startDate: date
                      ? dayjs(date[0]).format("YYYY-MM-DD")
                      : undefined,
                    endDate: date
                      ? dayjs(date[1]).format("YYYY-MM-DD")
                      : undefined,
                  }));
                }}
              />
            )}
          </Space>
        }
      >
        <Tabs
          onChange={(e) => {
            setCurrentTab(e);
          }}
        >
          <Tabs.TabPane tab={t("activity.location_have_active")} key="1">
            <Table
              {...tableProps}
              rowKey="id"
              pagination={{
                ...tableProps.pagination,
                showSizeChanger: true,
                showTotal: (total, range) =>
                  `${range[0]}-${range[1]} ${t("common.of")} ${total}`,
              }}
            >
              <Table.Column
                dataIndex="logo"
                title={t("activity.location_image")}
                width={120}
                render={(v, r: any) =>
                  v ? (
                    <ViewImage value={v} height={32} width={32} />
                  ) : (
                    <Avatar size={32} shape="square">
                      {r.name[0]}
                    </Avatar>
                  )
                }
              />
              <Table.Column
                dataIndex="name"
                title={t("activity.location_name")}
                render={(v, r: any) => (
                  <Link to={getDetailUrl(r.id, startDate)}>{v}</Link>
                )}
              />
              <Table.Column
                width={200}
                dataIndex={["tags"]}
                title={t("location.tags")}
                sorter
                render={(value: any) =>
                  value ? (
                    <div>
                      {value.split("|||").map((a: string) => (
                        <Tag style={{ marginBottom: 3 }} key={a}>
                          {a}
                        </Tag>
                      ))}
                    </div>
                  ) : (
                    ""
                  )
                }
              />
              <Table.Column
                dataIndex="totalCheckList"
                title={t("activity.total_checklist")}
                sorter={(a: any, b: any) => a.totalCheckList - b.totalCheckList}
                width={120}
              />
              <Table.Column
                dataIndex="totalDone"
                title={t("activity.total_done")}
                sorter={(a: any, b: any) => a.totalDone - b.totalDone}
                width={120}
              />
              <Table.Column
                dataIndex="totalOk"
                title={t("activity.total_ok")}
                width={120}
                sorter={(a: any, b: any) => a.totalOk - b.totalOk}
              />
              <Table.Column
                dataIndex="totalNg"
                title={t("activity.total_ng")}
                sorter={(a: any, b: any) => a.totalNg - b.totalNg}
                width={120}
              />
              <Table.Column
                dataIndex="totalMiss"
                title={t("activity.total_miss")}
                sorter={(a: any, b: any) => a.totalMiss - b.totalMiss}
                width={120}
              />
              <Table.Column
                dataIndex="totalReality"
                title={t("activity.total_real")}
                sorter={(a: any, b: any) => a.totalReality - b.totalReality}
                width={120}
              />
            </Table>
          </Tabs.TabPane>
          <Tabs.TabPane tab={t("activity.all_location")} key="2">
            <Table
              {...tablePropsLocation}
              rowKey="id"
              pagination={{
                ...tablePropsLocation.pagination,
                showSizeChanger: true,
                showTotal: (total, range) =>
                  `${range[0]}-${range[1]} ${t("common.of")} ${total}`,
              }}
            >
              <Table.Column
                dataIndex="qrCode"
                width={120}
                title={t("location.qrcode")}
                render={(v, r: any) => <QrLocation location={r} />}
              />
              <Table.Column dataIndex="name" title={t("location.name")} />
              <Table.Column
                width={200}
                dataIndex={["tags"]}
                title={t("location.tags")}
                sorter
                render={(value: any) =>
                  value ? (
                    <div>
                      {value.split("|||").map((a: string) => (
                        <Tag style={{ marginBottom: 3 }} key={a}>
                          {a}
                        </Tag>
                      ))}
                    </div>
                  ) : (
                    ""
                  )
                }
              />
              <Table.Column dataIndex="address" title={t("location.address")} />
              <Table.Column
                dataIndex="description"
                title={t("location.description")}
              />
              <Table.Column
                width={150}
                dataIndex={["userInCharge", "fullName"]}
                title={t("location.user_in_charge")}
              />
            </Table>
          </Tabs.TabPane>
        </Tabs>
      </Card>
    </List>
  );
};
