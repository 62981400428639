import { ImageField, ImageFieldProps } from "@refinedev/antd";
import { TOKEN_KEY, baseURL } from "authProvider";

export default function ViewImage(props: ImageFieldProps) {
  return (
    <ImageField
      {...props}
      style={{objectFit: 'cover'}}
      value={`${baseURL}/view-file/${props.value}?token=${localStorage.getItem(
        TOKEN_KEY
      )}`}
    />
  );
}
