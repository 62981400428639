import { TOKEN_KEY, baseURL } from "authProvider";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

function Editor({ onChange, value }: any) {
  const editorRef = useRef<any>();
  const { CKEditor, ClassicEditor } = editorRef.current || {};

  useEffect(() => {
    editorRef.current = {
      CKEditor: require("@ckeditor/ckeditor5-react").CKEditor, // v3+
      ClassicEditor: require("@ckeditor/ckeditor5-build-classic"),
    };
  }, []);
  const [editorLoaded, setEditorLoaded] = useState(false);
  const [data, setData] = useState("");

  useEffect(() => {
    setEditorLoaded(true);
  }, []);

  return (
    <Styled>
      {editorLoaded ? (
        <CKEditor
          theme="light"
          config={{
            // @ts-ignore
            extraPlugins: [uploadPlugin],
            removePlugins: ['MediaEmbed'],
          }}
          type=""
          name={"edit"}
          editor={ClassicEditor}
          data={value || ""}
          onChange={(event: any, editor: any) => {
            const data = editor.getData();
            onChange(data);
          }}
          // onInit={(editor: any) => {
          //   editor.ui.view.editable.element.style.height = "200px";
          //   editor.plugins.get("FileRepository").createUploadAdapter =
          //     function (loader: any) {
          //       return new UploadAdapter(loader);
          //     };
          // }}
        />
      ) : (
        <div>Editor loading</div>
      )}
    </Styled>
  );
}

export default Editor;

const Styled = styled.div`
  color: black;

  .ck-editor__editable {
    min-height: 300px;
  }
`;

function uploadAdapter(loader: any) {
  return {
    upload: () => {
      return new Promise(async (resolve, reject) => {
        try {
          const file = await loader.file;
          const response = await axios.request({
            method: "POST",
            url: `${baseURL}/upload.admin`,
            data: {
              "files[]": file,
            },
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: "Bearer " + localStorage.getItem(TOKEN_KEY),
            },
          });
          console.log(response);
          resolve({
            default: `${baseURL}/view-public/${response.data[0].name}`,
          });
        } catch (error) {
          reject("Có lỗi");
        }
      });
    },
    abort: () => {},
  };
}
function uploadPlugin(editor: any) {
  editor.plugins.get("FileRepository").createUploadAdapter = (loader: any) => {
    return uploadAdapter(loader);
  };
}
