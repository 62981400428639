import type { RefineThemedLayoutV2HeaderProps } from "@refinedev/antd";
import {
  useGetIdentity,
  useGetLocale,
  useSetLocale,
  useTranslate,
} from "@refinedev/core";
import {
  Layout as AntdLayout,
  Avatar,
  Button,
  Dropdown,
  Menu,
  Space,
  Typography,
  theme,
} from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { DownOutlined } from "@ant-design/icons";
import AvatarCustom from "components/avatar";

const { Text } = Typography;
const { useToken } = theme;

export const Header: React.FC<RefineThemedLayoutV2HeaderProps> = ({}) => {
  const { token } = useToken();
  const { data: user } = useGetIdentity<any>();

  const headerStyles: React.CSSProperties = {
    backgroundColor: token.colorBgElevated,
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    padding: "0px 24px",
    height: "64px",
  };

  const { i18n } = useTranslation();
  const locale = useGetLocale();
  const changeLanguage = useSetLocale();

  const currentLocale = locale();

  const t = useTranslate();

  const menu = (
    <Menu selectedKeys={currentLocale ? [currentLocale] : []}>
      {[...(i18n.languages || [])].sort().map((lang: string) => (
        <Menu.Item
          key={lang}
          onClick={() => changeLanguage(lang)}
          icon={
            <span style={{ marginRight: 8 }}>
              <Avatar
                size={16}
                src={`/images/flags/${lang == "eng" ? "en" : "vi"}.svg`}
              />
            </span>
          }
        >
          {lang === "eng" ? "English" : ""}
          {lang === "vi" ? "Tiếng Việt" : ""}
          {lang === "key" ? "Key" : ""}
        </Menu.Item>
      ))}
    </Menu>
  );

  return (
    <AntdLayout.Header style={headerStyles}>
      <Dropdown overlay={menu}>
        <Button type="link">
          <Space>
            <Avatar size={16} src={`/images/flags/${currentLocale}.svg`} />
            {currentLocale === "eng" ? "English" : ""}
            {currentLocale === "vi" ? "Tiếng Việt" : ""}
            {currentLocale === "key" ? "Key" : ""}
            <DownOutlined />
          </Space>
        </Button>
      </Dropdown>
      <Link to="/profile">
        <Space style={{ marginLeft: "8px" }} size="middle">
          <div style={{ lineHeight: 1, padding: 12 }}>
            <Text strong>{user?.fullName || user?.email}</Text>
            <br />
            {user?.role && (
              <Text>
                {t("common.role." + user?.role.name)}
                {/* {user?.role.name == "company_manager" && "Quản lý"}
                {user?.role.name == "company_admin" && "Quản trị"}
                {user?.role.name == "supper_admin" && "Super admin"} */}
              </Text>
            )}
          </div>
          {user?.role.name == "company_manager" &&  (
            <AvatarCustom
              src={user?.avatar}
              size={40}
            >
              { user?.role.name == "company_admin" ? user?.company?.companyCode: user?.fullName[0]}
            </AvatarCustom>
          )}
        </Space>
      </Link>
    </AntdLayout.Header>
  );
};
