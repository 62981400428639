import React, { useEffect } from "react";
import {
  IResourceComponentsProps,
  useShow,
  useOne,
  useList,
  useTranslate,
  useCreate,
} from "@refinedev/core";
import {
  DateField,
  EditButton,
  ImageField,
  Show,
  useForm,
  useModal,
  useModalForm,
  useSelect,
  useTable,
} from "@refinedev/antd";
import {
  Form,
  Input,
  DatePicker,
  Row,
  Col,
  Card,
  Table,
  Space,
  Button,
  Select,
  Tag,
  Drawer,
  Typography,
  Descriptions,
  Calendar,
  Badge,
  Avatar,
  notification,
  Modal,
  QRCode,
  TimeRangePickerProps,
  Tabs,
  Popover,
} from "antd";
import dayjs, { Dayjs } from "dayjs";
import { useState } from "react";
import {
  CalendarOutlined,
  CarryOutOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  DeleteOutlined,
  DownloadOutlined,
  EditOutlined,
  EnvironmentOutlined,
  EyeOutlined,
  LeftOutlined,
  PhoneOutlined,
  RightOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Link, useParams } from "react-router-dom";
import ViewImage from "components/view_image";
import AvatarCustom from "components/avatar";
import _ from "lodash";
import { basePDFURL, baseURL, TOKEN_KEY } from "../../authProvider";
import axios from "axios";
import { useLocation, useSearchParams } from "react-router-dom";
import { ResultCheckList } from "./Result";

export const ActivityUserDetail: React.FC<IResourceComponentsProps> = () => {
  const { queryResult } = useShow();
  const { id } = useParams();
  const t = useTranslate();
  const record = queryResult?.data?.data || {};

  const [baseFilter, setBaseFilter] = useState<any>({});
  const [searchParams] = useSearchParams();
  const startDate = searchParams.get("startDate");
  const initialDate = startDate ? dayjs(startDate) : dayjs();

  const [month, setMonth] = useState(initialDate.format("YYYY-MM-DD"));

  const managerId = localStorage.getItem("MANAGER_ID") || "";
  useEffect(() => {
    if (managerId != "") {
      setBaseFilter({ managerId });
    }
  }, [managerId]);

  const { data: dataCalendar } = useOne({
    resource: "report_template",
    id: id,
    meta: {
      type: "caculate",
      value: {
        userId: id,
        managerId: managerId,
        date: month,
      },
    },
  });
  const dataCalendarList = dataCalendar?.data?.data || [];

  const { tableProps, setFilters, filters, setCurrent } = useTable({
    syncWithLocation: false,
    resource: "user_work",
    filters: {
      permanent: [{ field: "userId", operator: "eq", value: id }],
    },
    meta: {
      baseFilter: baseFilter,
    },
  });

  const { data: dataSummaryRaw } = useOne({
    resource: "user_work",
    id: id,
    meta: {
      type: "list_summary",
      value: {
        ...baseFilter,
        filter: [
          ...filters.filter((a: any) => a.field != "workCode"),
          // {
          //   field: "userId",
          //   operator: "$eq",
          //   value: id,
          // },
        ],
      },
    },
  });
  const dataSummary = dataSummaryRaw?.data || {};
  dataSummary.totalDone = dataSummary.totalOk * 1 + dataSummary.totalNg * 1;

  const assignId = filters.find(
    (a: any) => a.field == "assignTemplate.id"
  )?.value;
  const { data: asssignData } = useOne({
    resource: "assign",
    id: assignId,
  });

  const templateId =
    filters.find((a: any) => a.field == "assignTemplate.templateId")?.value ||
    asssignData?.data?.templateId;
  console.log("templateId", templateId);

  const { data: questionTemplate } = useList({
    resource: "question",
    filters: [
      {
        field: "templateId",
        operator: "eq",
        value: templateId,
      },
    ],
    pagination: { pageSize: 10000 },
  });

  let questionList = questionTemplate?.data || [];
  questionList = _.orderBy(questionList, ["sort"], ["asc"]);

  console.log(questionList);
  return (
    <Show
      isLoading={queryResult.isFetching}
      breadcrumb={null}
      headerButtons={<></>}
      title={record?.fullName || "..."}
      contentProps={{
        bodyStyle: { padding: 0, background: "none", boxShadow: "none" },
        style: { background: "none", boxShadow: "none" },
      }}
    >
      <div style={{ display: "flex", gap: 20 }}>
        <div style={{ width: 266 }}>
          <Card>
            <div style={{ padding: 10 }}>
              <div style={{ textAlign: "center" }}>
                {/* <QRCode value={record?.userId ?? ''} size={150} /> */}
                {record.avatar ? (
                  <AvatarCustom size={72} src={record.avatar} />
                ) : (
                  <Avatar size={72}>{record.fullName?.[0]}</Avatar>
                )}
                <Typography.Title level={5}>{record.fullName}</Typography.Title>
              </div>
              <Row gutter={[6, 6]} style={{ marginBottom: 6 }}>
                <Col>
                  <PhoneOutlined />
                </Col>
                <Col span={18}>{record?.phone}</Col>
              </Row>
              <Row gutter={[6, 6]} style={{ marginBottom: 6 }}>
                <Col>
                  <CalendarOutlined />
                </Col>
                <Col span={18}>
                  {dayjs(record?.createdTime).format("DD/MM/YYYY")}
                </Col>
              </Row>
              <Row gutter={[6, 6]} style={{ marginBottom: 6 }}>
                <Col>
                  {record?.status == "ACTIVE" && (
                    <CheckCircleOutlined style={{ color: "#52C41A" }} />
                  )}
                  {record?.status == "INACTIVE" && (
                    <CloseCircleOutlined style={{ color: "red" }} />
                  )}
                </Col>
                <Col span={18}>
                  {record?.status == "ACTIVE" && t("common.active")}
                  {record?.status == "INACTIVE" && t("common.inactive")}
                </Col>
              </Row>
            </div>
          </Card>
          <br />

          <Card title={t("activity.calendar")} size="small">
            <Calendar
              mode="month"
              fullscreen={false}
              headerRender={() => (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: 10,
                    paddingBottom: 12,
                  }}
                >
                  <Button
                    onClick={() =>
                      setMonth(
                        dayjs(month).subtract(1, "M").format("YYYY-MM-DD")
                      )
                    }
                  >
                    <LeftOutlined />
                  </Button>
                  {t("common.month")} {dayjs(month).format("MM/YYYY")}
                  <Button
                    onClick={() =>
                      setMonth(dayjs(month).add(1, "M").format("YYYY-MM-DD"))
                    }
                  >
                    <RightOutlined />
                  </Button>
                </div>
              )}
              defaultValue={dayjs(initialDate)}
              value={dayjs(month)}
              onSelect={(d) => {
                setMonth(dayjs(d).format("YYYY-MM-DD"));
                setFilters(
                  [
                    {
                      field: "startTime",
                      operator: "between",
                      value: [
                        dayjs(d).format("YYYY-MM-DD 00:00:00"),
                        dayjs(d).format("YYYY-MM-DD 23:59:59"),
                      ],
                    },
                  ],
                  "replace"
                );
                setCurrent(1);
              }}
              cellRender={(d) => {
                var items: any = dataCalendarList.find(
                  (a: any) => dayjs(a.date).format("DD/MM") == d.format("DD/MM")
                );

                var ok = items?.value?.OK;
                var ng = items?.value?.NG;
                var miss = items?.value?.MISS;
                return (
                  <div
                    style={{
                      display: "flex",
                      gap: 4,
                      justifyContent: "center",
                    }}
                  >
                    {ok > 0 && <Badge color="green" />}
                    {ng > 0 && <Badge color="blue" />}
                    {miss > 0 && <Badge color="red" />}
                  </div>
                );
              }}
            />

            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: 16,
                padding: 12,
              }}
            >
              <Space size="small">
                <div
                  style={{
                    backgroundColor: "#FF4D4F",
                    height: 14,
                    width: 14,
                    borderRadius: 3,
                  }}
                />
                {t("common.MISS")}
              </Space>

              <Space size="small">
                <div
                  style={{
                    backgroundColor: "#40A9FF",
                    height: 14,
                    width: 14,
                    borderRadius: 3,
                  }}
                />
                {t("common.NG")}
              </Space>

              <Space size="small">
                <div
                  style={{
                    backgroundColor: "#73D13D",
                    height: 14,
                    borderRadius: 3,
                    width: 14,
                  }}
                />
                {t("common.OK")}
              </Space>
            </div>
          </Card>
        </div>
        <div style={{ flex: 1, width: "calc(100% - 266px)" }}>
          <FilterBlock
            filters={filters}
            setFilters={(e: any) => {
              setFilters(e, "replace");
              setCurrent(1);
            }}
            baseFilter={baseFilter}
            searchParams={searchParams}
          />
          <br />
          <Row gutter={[12, 12]}>
            <Col md={4}>
              <Card>
                <Typography.Text>{t("board.work")}</Typography.Text>
                <Typography.Title level={2} style={{ color: "#BAE637" }}>
                  {dataSummary?.totalDone || "-"}
                </Typography.Title>
                <Typography.Title level={5} style={{ color: "#BAE637" }}>
                  {t("board.work_done")}
                </Typography.Title>
              </Card>
            </Col>

            <Col md={4}>
              <Card>
                <Typography.Text>{t("board.work")}</Typography.Text>
                <Typography.Title level={2} style={{ color: "#73D13D" }}>
                  {dataSummary?.totalOk || "-"}
                </Typography.Title>
                <Typography.Title level={5} style={{ color: "#73D13D" }}>
                  {t("board.work_OK")}
                </Typography.Title>
              </Card>
            </Col>
            <Col md={4}>
              <Card>
                <Typography.Text>{t("board.work")}</Typography.Text>
                <Typography.Title level={2} style={{ color: "#40A9FF" }}>
                  {dataSummary?.totalNg || "-"}
                </Typography.Title>
                <Typography.Title level={5} style={{ color: "#40A9FF" }}>
                  {t("board.work_NG")}
                </Typography.Title>
              </Card>
            </Col>
            <Col md={4}>
              <Card>
                <Typography.Text>{t("board.work")}</Typography.Text>
                <Typography.Title level={2} style={{ color: "#FF4D4F" }}>
                  {dataSummary?.totalMiss || "-"}
                </Typography.Title>
                <Typography.Title level={5} style={{ color: "#FF4D4F" }}>
                  {t("board.work_MISS")}
                </Typography.Title>
              </Card>
            </Col>
            <Col md={4}>
              <Card>
                <Typography.Text>{t("board.work_CONNECT")}</Typography.Text>
                <Typography.Title level={2} style={{ color: "#40A9FF" }}>
                  {dataSummary?.totalParentLinked || "-"}
                </Typography.Title>
                <Typography.Title level={5} style={{ color: "#40A9FF" }}>
                  {t("board.work_CONNECT")}
                </Typography.Title>
              </Card>
            </Col>
            <Col md={4}>
              <Card>
                <Typography.Text>{t("board.work_UNCONNECT")}</Typography.Text>
                <Typography.Title level={2} style={{ color: "#FF4D4F" }}>
                  {dataSummary?.totalParentUnlinked || "-"}
                </Typography.Title>
                <Typography.Title level={5} style={{ color: "#FF4D4F" }}>
                  {t("board.work_UNCONNECT")}
                </Typography.Title>
              </Card>
            </Col>
          </Row>
          <br />
          <Card
            title={
              <Space>
                <Typography.Text>{t("activity.work_code")}:</Typography.Text>
                <Input.Search
                  allowClear
                  placeholder={t("common.search")}
                  onSearch={(e) => {
                    setFilters([
                      {
                        field: "workCode",
                        operator: "contains",
                        value: e || undefined,
                      },
                    ]);
                    setCurrent(1);
                  }}
                />
              </Space>
            }
          >
            <Tabs>
              <Tabs.TabPane tab={t("activity.tab_info")} key="INFO">
                <Table
                  {...tableProps}
                  size="small"
                  pagination={{
                    ...tableProps.pagination,
                    showSizeChanger: true,
                    showTotal: (total, range) =>
                      `${range[0]}-${range[1]} ${t("common.of")} ${total}`,
                  }}
                >
                  <Table.Column
                    dataIndex={["workCode"]}
                    title={t("activity.work_code")}
                    render={(v, r: any) => (
                      <ResultCheckList workId={r.id} workCode={v} />
                    )}
                  />
                  <Table.Column
                    dataIndex={["id"]}
                    title={t("activity.assign")}
                    render={(v, r: any) => (
                      <Space direction="vertical" size="small">
                        <span>{r.assignTemplate?.name}</span>
                        {(r.location || r.assignTemplate?.location) && (
                          <span style={{ opacity: 0.8 }}>
                            <EnvironmentOutlined />
                            {r.location?.name ||
                              r.assignTemplate?.location?.name}
                          </span>
                        )}
                      </Space>
                    )}
                  />
                  <Table.Column
                    dataIndex={["result"]}
                    title={t("activity.result")}
                    render={(r) => {
                      if (r == "MISS")
                        return <Tag color="red">{t("common.MISS")}</Tag>;
                      if (r == "OK")
                        return <Tag color="green">{t("common.OK")}</Tag>;
                      if (r == "NG")
                        return <Tag color="blue">{t("common.NG")}</Tag>;
                      return r;
                    }}
                  />
                  <Table.Column
                    dataIndex={["parentCode"]}
                    title={t("activity.connected")}
                    render={(v, r: any) => {
                      return v ? t("common.YES") : t("common.NO");
                    }}
                  />
                  <Table.Column
                    dataIndex={["scoreReiceve"]}
                    title={t("activity.score_reiceve")}
                  />
                  <Table.Column
                    dataIndex={["startTime"]}
                    title={t("activity.working_time")}
                    render={(v, r: any) => {
                      if (r.assignTemplate?.checkListType == "INCURRED") {
                        return "-";
                      }
                      return (
                        <div>
                          <div>
                            <DateField value={v} format="DD/MM/YYYY" />
                          </div>
                          <Space size="small">
                            <DateField
                              value={dayjs(r.startTime).utc()}
                              format="HH:mm"
                            />
                            -
                            <DateField
                              value={dayjs(r.endTime).utc()}
                              format="HH:mm"
                            />
                          </Space>
                        </div>
                      );
                    }}
                  />
                  <Table.Column
                    dataIndex={["submitTime"]}
                    title={t("activity.working_finish")}
                    render={(v, r: any) => {
                      if (!v) {
                        return "-";
                      }
                      return (
                        <div>
                          <div>
                            <DateField
                              value={dayjs(v).utc()}
                              format="DD/MM/YYYY"
                            />
                          </div>
                          <Space size="small">
                            <DateField value={dayjs(v).utc()} format="HH:mm" />
                          </Space>
                        </div>
                      );
                    }}
                  />
                  <Table.Column
                    dataIndex={["assignTemplate", "checkListType"]}
                    title={t("assign.type")}
                    render={(r) => {
                      return t("common.assign_type_" + r);
                    }}
                  />
                </Table>
              </Tabs.TabPane>
              {questionList.length > 0 && (
                <Tabs.TabPane tab={t("activity.tab_answer")} key="QUESTION">
                  <Table
                    {...tableProps}
                    size="small"
                    scroll={{ x: 150 * questionList.length }}
                    pagination={{
                      ...tableProps.pagination,
                      showSizeChanger: true,
                      showTotal: (total, range) =>
                        `${range[0]}-${range[1]} ${t("common.of")} ${total}`,
                    }}
                  >
                    <Table.Column
                      width={150}
                      dataIndex={["workCode"]}
                      title={t("activity.work_code")}
                      render={(v, r: any) => (
                        <ResultCheckList workId={r.id} workCode={v} />
                      )}
                    />
                    {questionList.map((q: any) => {
                      return (
                        <Table.Column
                          key={q.id}
                          dataIndex={["question"]}
                          title={q.name}
                          width={150}
                          render={(v, r: any) => {
                            const q2 = r.assignTemplate?.questions.find(
                              (a: any) => a.name == q.name
                            );
                            const re = r.anwsers.find(
                              (a: any) => a.questId == q2?.id
                            );

                            const dataQuestion = re?.dataQuestion || [];
                            const imgs = re?.links || [];
                            return (
                              <div>
                                {dataQuestion.map((a: any) => (
                                  <p
                                    style={{
                                      wordBreak: "break-word",
                                    }}
                                  >
                                    {a.value}
                                  </p>
                                ))}
                                {imgs.map((a: any) => (
                                  <ViewImage
                                    value={a.link}
                                    height={48}
                                    width={48}
                                    wrapperStyle={{
                                      marginRight: 4,
                                      marginBottom: 4,
                                    }}
                                  />
                                ))}
                              </div>
                            );
                          }}
                        />
                      );
                    })}
                  </Table>
                </Tabs.TabPane>
              )}
            </Tabs>
          </Card>
          <br />
          <Row gutter={[12, 12]}>
            <Col span={12}>
              <Card title={t("group.group")} size="small">
                <Table
                  dataSource={record.groups}
                  size="small"
                  pagination={{ hideOnSinglePage: true }}
                >
                  <Table.Column dataIndex={["id"]} title={t("group.id")} />
                  <Table.Column dataIndex={["name"]} title={t("group.name")} />
                </Table>
              </Card>
            </Col>
            <Col span={12}>
              <Card title={t("location.location")} size="small">
                <Table
                  dataSource={record.locations}
                  size="small"
                  pagination={{ hideOnSinglePage: true }}
                >
                  <Table.Column dataIndex={["id"]} title={t("location.id")} />
                  <Table.Column
                    dataIndex={["name"]}
                    title={t("location.name")}
                  />
                </Table>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </Show>
  );
};
const FilterBlock = ({
  filters,
  setFilters,
  baseFilter,
  searchParams,
}: any) => {
  const { id } = useParams();
  const { data } = useOne({
    resource: "user_work",
    id: id,
    meta: {
      type: "related_list",
      // key: 'userId'
      value: {
        userId: id,
        ...baseFilter,
      },
    },
  });
  const selectPropsTemplate: any = data?.data?.templates || [];
  const selectPropsAssign: any = data?.data?.assignTemplates || [];
  const selectPropsLocation: any = data?.data?.locations || [];

  const { formProps, form } = useForm({
    warnWhenUnsavedChanges: false,
  });

  useEffect(() => {
    // form.resetFields();
    filters.map((f: any) => {
      console.log(f);
      if (f.field == "startTime") {
        form.setFieldValue("date", [dayjs(f.value[0]), dayjs(f.value[1])]);
      } else {
        form.setFieldValue(f.field, f.value);
      }
    });
  }, [filters.map((a: any) => a.value + a.field)?.join(",")]);

  const startDate = searchParams.get("startDate");
  const initialDate = startDate ? dayjs(startDate) : dayjs();

  useEffect(() => {
    setFilters(
      [
        {
          field: "startTime",
          operator: "between",
          value: [
            initialDate.format("YYYY-MM-DD 00:00:00"),
            initialDate.format("YYYY-MM-DD 23:59:59"),
          ],
        },
      ].filter((a) => a),
      "replace"
    );
  }, []);

  const t = useTranslate();

  const initialValues = {
    date: [initialDate, initialDate] as [Dayjs | null, Dayjs | null],
  };

  const rangePresets: TimeRangePickerProps["presets"] = [
    { label: t("common.this_month"), value: [dayjs().set("D", 1), dayjs()] },
    {
      label: t("common.last_month"),
      value: [dayjs().set("D", 1).subtract(1, "M"), dayjs().set("D", 1)],
    },
    {
      label: t("common.this_year"),
      value: [dayjs().set("M", 0).set("D", 1), dayjs()],
    },
  ];
  return (
    <Card>
      <Form
        {...formProps}
        initialValues={initialValues}
        onValuesChange={(e) => {
          const d = form.getFieldsValue() as any;
          const f = [
            {
              field: "result",
              operator: "eq",
              value: d.result != "ALL" ? d.result : undefined,
            },
            {
              field: "assignTemplate.templateId",
              operator: "eq",
              value: d.templateId,
            },
            {
              field: "preLocationId",
              operator: "eq",
              value: d.locationId,
            },
            {
              field: "status",
              operator: "eq",
              value: d.status,
            },
            {
              field: "assignTemplate.id",
              operator: "eq",
              value: d.assignId,
            },
          ];
          if (d.date) {
            f.push({
              field: "startTime",
              operator: "between",
              value: [
                dayjs(d.date[0]).format("YYYY-MM-DD 00:00:00"),
                dayjs(d.date[1]).format("YYYY-MM-DD 23:59:59"),
              ],
            });
          }
          if (d.connected) {
            if (d.connected == "CONNECTED") {
              f.push({
                field: "parentId",
                operator: "notnull",
                value: true,
              });
            }
            if (d.connected == "NOT_CONNECTED") {
              f.push({
                field: "parentId",
                operator: "isnull",
                value: true,
              });
            }
          }
          console.log({ d, f });
          setFilters(f, "replace");
        }}
      >
        <Row gutter={[12, 12]}>
          <Col span={8}>
            <Form.Item name="assignId" label={t("filter.assign")}>
              <Select
                showSearch
                style={{ width: "100%" }}
                placeholder={t("filter.assign_all")}
                allowClear
                options={selectPropsAssign.map((a: any) => ({
                  value: a.id,
                  label: a.name,
                }))}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="templateId" label={t("filter.template")}>
              <Select
                showSearch
                style={{ width: "100%" }}
                placeholder={t("filter.template_all")}
                allowClear
                options={selectPropsTemplate.map((a: any) => ({
                  value: a.id,
                  label: a.name,
                }))}
              />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item name="locationId" label={t("filter.location")}>
              <Select
                style={{ width: "100%" }}
                placeholder={t("filter.location_all")}
                showSearch
                allowClear
                options={selectPropsLocation.map((a: any) => ({
                  value: a.id,
                  label: a.name,
                }))}
              />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item name="result" label={t("filter.result")}>
              <Select
                style={{ width: "100%" }}
                placeholder={t("filter.result_all")}
                allowClear
              >
                <Select.Option value="ALL">{t("common.ALL")}</Select.Option>
                <Select.Option value="MISS">
                  {t("common.full_MISS")}
                </Select.Option>
                <Select.Option value="NG">{t("common.full_NG")}</Select.Option>
                <Select.Option value="OK">{t("common.full_OK")}</Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="date" label={t("filter.date")}>
              <DatePicker.RangePicker
                presets={rangePresets}
                style={{ width: "100%" }}
                allowClear
                placeholder={[t("common.select_start"), t("common.select_end")]}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item name="connected" label={t("filter.connected")}>
              <Select
                style={{ width: "100%" }}
                placeholder={t("filter.status_all")}
                allowClear
              >
                <Select.Option value="ALL">{t("common.ALL")}</Select.Option>
                <Select.Option value="CONNECTED">
                  {t("common.status_connected")}
                </Select.Option>
                <Select.Option value="NOT_CONNECTED">
                  {t("common.status_unconnected")}
                </Select.Option>
                {/* <Select.Option value="N_A">
                  {t("common.status_na")}
                </Select.Option> */}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};
