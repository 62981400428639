import React, {useEffect} from "react";
import { IResourceComponentsProps, useTranslate } from "@refinedev/core";
import { useTable, List, useSelect } from "@refinedev/antd";
import {
  Table,
  Space,
  Avatar,
  Card,
  DatePicker,
  Select,
  Typography,
  TimeRangePickerProps, Input,
} from "antd";
import { useState } from "react";
import { Link } from "react-router-dom";
import ViewImage from "components/view_image";
import { useLocation, useSearchParams } from 'react-router-dom';
import dayjs, { Dayjs } from "dayjs";

export const ActivityUserList: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();
  const [baseFilter, setBaseFilter] = useState<any>({});
  const [searchParams] = useSearchParams();

  const startDate = searchParams.get('startDate');
  const initialDate = startDate ? dayjs(startDate) : dayjs();
  const startDateFilter = startDate ? initialDate: initialDate.startOf('month');
  const endDateFilter =  startDate ? initialDate: initialDate.endOf('month');

  const initialValues = {
    date: [startDateFilter, endDateFilter] as [Dayjs | null, Dayjs | null],
  };

  const managerId = localStorage.getItem("MANAGER_ID") || "";
  useEffect(() => {
    if(managerId != ""){
      setBaseFilter({managerId})
    }
  }, [managerId]);

  useEffect(() => {
    setBaseFilter((c: any) => ({
      ...c,
      startDate: startDateFilter.format("YYYY-MM-DD"),
      endDate: endDateFilter.format("YYYY-MM-DD"),
    }));
  }, []);

  const { tableProps } = useTable({
    syncWithLocation: true,
    meta: {
      baseFilter: baseFilter,
    },
  });

  const rangePresets: TimeRangePickerProps["presets"] = [
    { label: t("common.this_month"), value: [dayjs().set("D", 1), dayjs()] },
    {
      label: t("common.last_month"),
      value: [dayjs().set("D", 1).subtract(1, "M"), dayjs().set("D", 1)],
    },
    {
      label: t("common.this_year"),
      value: [dayjs().set("M", 0).set("D", 1), dayjs()],
    },
  ];

  const getDetailUrl = (userId: string, startDate: string|null) =>{
    if(startDate){
      return "/activity_user/show/" + userId +"?startDate="+startDate
    }
    return "/activity_user/show/" + userId
  }

  return (
    <List title={t("activity.title_user")}>
      <Card
        title={
          <Space>
            <Typography.Text>
              {t("activity.label_search_user")}:
            </Typography.Text>
            <Input.Search
                allowClear
                placeholder={t("group.search")}
                onSearch={(e) => {
                  setBaseFilter((c: any) => ({
                    ...c,
                    fullName: e || undefined,
                  }));
                }}
            />
          </Space>
        }
        extra={
          <DatePicker.RangePicker
            presets={rangePresets}
            allowClear
            placeholder={[t("common.select_start"), t("common.select_end")]}
            defaultValue={initialValues.date}
            onChange={(date) => {
              setBaseFilter((c: any) => ({
                ...c,
                startDate: date
                  ? dayjs(date[0]).format("YYYY-MM-DD")
                  : undefined,
                endDate: date ? dayjs(date[1]).format("YYYY-MM-DD") : undefined,
              }));
            }}
          />
        }
      >
        <Table
          {...tableProps}
          rowKey="id"
          pagination={{
            ...tableProps.pagination,
            showSizeChanger: true,
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} ${t("common.of")} ${total}`,
          }}
        >
          <Table.Column
            dataIndex="avatar"
            title={t("activity.avatar")}
            width={90}
            render={(v, r: any) =>
              v ? (
                <ViewImage value={v} height={32} width={32} />
              ) : (
                <Avatar size={32} shape="square">
                  {r.fullName?.[0]}
                </Avatar>
              )
            }
          />
          <Table.Column
            dataIndex="fullName"
            title={t("activity.user_name")}
            render={(v, r: any) => (
              <Link to={getDetailUrl(r.userId,startDate)}>{v}</Link>
            )}
          />
          <Table.Column
            dataIndex="scoreReceive"
            title={t("activity.score")}
            width={120}
            sorter={(a: any, b: any) => a.scoreReceive - b.scoreReceive}
          />
          <Table.Column
            dataIndex="totalCheckList"
            title={t("activity.total_checklist")}
            sorter={(a: any, b: any) => a.totalCheckList - b.totalCheckList}
            width={120}
          />
          <Table.Column
            dataIndex="totalDone"
            title={t("activity.total_done")}
            sorter={(a: any, b: any) => a.totalDone - b.totalDone}
            width={120}
          />
          <Table.Column
            dataIndex="totalOk"
            title={t("activity.total_ok")}
            width={120}
            sorter={(a: any, b: any) => a.totalOk - b.totalOk}
          />
          <Table.Column
            dataIndex="totalNg"
            title={t("activity.total_ng")}
            sorter={(a: any, b: any) => a.totalNg - b.totalNg}
            width={120}
          />
          <Table.Column
            dataIndex="totalMiss"
            title={t("activity.total_miss")}
            sorter={(a: any, b: any) => a.totalMiss - b.totalMiss}
            width={120}
          />
          <Table.Column
            dataIndex="totalReality"
            title={t("activity.total_real")}
            sorter={(a: any, b: any) => a.totalReality - b.totalReality}
            width={120}
          />
          {/*<Table.Column*/}
          {/*  dataIndex="totalPlan"*/}
          {/*  sorter={(a: any, b: any) => a.totalPlan - b.totalPlan}*/}
          {/*  title={t("activity.total_plan")}*/}
          {/*  width={120}*/}
          {/*/>*/}
        </Table>
      </Card>
    </List>
  );
};
