import React, { useEffect, useState } from "react";
import {
  IResourceComponentsProps,
  useTranslate,
  useList,
  useShow,
  useUpdate,
} from "@refinedev/core";
import { DeleteButton, Show, useModalForm } from "@refinedev/antd";
import {
  Form,
  Input,
  Row,
  Col,
  Typography,
  Button,
  Modal,
  Select,
  Tag,
  Space,
  InputNumber,
  Card,
  Table,
  ButtonProps,
  Spin,
} from "antd";
import { IQuestion } from "types";
import Editor from "components/editor";
import _ from "lodash";
import {
  CopyOutlined,
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
} from "@ant-design/icons";

export const TemplateShow: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();
  const { queryResult } = useShow();
  const record = queryResult?.data?.data;
  const { data: dataQuestions, isFetching } = useList<IQuestion>({
    resource: "question",
    filters: [
      {
        field: "templateId",
        operator: "eq",
        value: record?.id,
      },
    ],
    pagination: {
      pageSize: 10000,
    },
  });
  const questions = dataQuestions?.data || [];

  const { mutate } = useUpdate();
  const roleName = localStorage.getItem("ROLE_NAME");
  const [guideText, setGuideText] = useState("");

  return (
    <Show
      isLoading={queryResult.isFetching}
      title={record?.name || t("template.template")}
      breadcrumb={null}
      contentProps={{
        bodyStyle: { padding: 0, background: "none" },
        style: { background: "none" },
      }}
      headerButtons={
        <>
          <Button
            type="primary"
            hidden={
              !(roleName == "company_admin" && record?.status == "INACTIVE")
            }
            disabled={questions.length == 0}
            onClick={() => {
              mutate({
                resource: "template",
                id: record?.id || "",
                values: {
                  id: record?.id,
                  status: "ACTIVE",
                },
                meta: {
                  type: "approve",
                },
              });
            }}
          >
            {t("template.approve")}
          </Button>
        </>
      }
    >
      <Modal
        width={1200}
        open={!!guideText}
        title={t("question.guide")}
        onOk={() => setGuideText("")}
        onCancel={() => setGuideText("")}
        okText={t("common.ok")}
        cancelButtonProps={{ hidden: true }}
      >
        <div dangerouslySetInnerHTML={{ __html: guideText }}></div>
      </Modal>
      <Card title={t("template.detail_info")}>
        <Form layout="vertical">
          <Row gutter={12}>
            <Col span={24}>
              <Form.Item label={t("template.name")}>
                <Input disabled value={record?.name} />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item label={t("template.note")}>
                <Input.TextArea
                  rows={10}
                  value={record?.description}
                  disabled
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Card>

      <br />
      <Spin spinning={isFetching}>
        <Card
          title={t("question.list")}
          extra={
            record &&
            record.status == "INACTIVE" && (
              <ModalQuestion
                mode="create"
                templateId={record.id ?? ""}
                hidden={roleName != "company_manager"}
                questions={questions}
                buttonProps={{
                  type: "dashed",
                  icon: <PlusOutlined />,
                }}
              >
                {t("question.add_new")}
              </ModalQuestion>
            )
          }
        >
          <Table
            dataSource={_.sortBy(questions, "sort")}
            pagination={{ hideOnSinglePage: false, showSizeChanger: true }}
          >
            <Table.Column
              title={t("question.order")}
              dataIndex="sort"
              width={150}
            />

            <Table.Column
              title={t("question.name")}
              dataIndex="name"
              render={(v, r: any) => {
                return (
                  <div>
                    <div>{v}</div>
                    <p style={{ whiteSpace: "pre-line", fontSize: 12 }}>
                      {r.description}
                    </p>
                  </div>
                );
              }}
            />
            <Table.Column
              title={t("question.guide")}
              dataIndex="guide"
              render={(v) => {
                if (!v) return;
                return (
                  <a
                    className="ant-typography "
                    onClick={() => setGuideText(v)}
                  >
                    {t("question.guide_detail")}
                  </a>
                );
              }}
            />
            <Table.Column
              title={t("question.type")}
              dataIndex="typeQuestion"
              width={150}
              render={(v) => {
                if (v == "NORMAL")
                  return <Tag color="blue">{t("question.type_NORMAL")}</Tag>;
                if (v == "CAPTURE")
                  return <Tag color="purple">{t("question.type_CAPTURE")}</Tag>;
                if (v == "QR_CODE")
                  return <Tag color="volcano">{t("question.type_QRCODE")}</Tag>;
                if (v == "TEXT_INPUT")
                  return <Tag color="geekblue">{t("question.type_TEXT")}</Tag>;
                if (v == "RANGES")
                  return <Tag color="gold">{t("question.type_RANGES")}</Tag>;
                if (v == "OPTIONS")
                  return <Tag color="green">{t("question.type_OPTIONS")}</Tag>;
                if (v == "CHECKBOXS")
                  return (
                    <Tag color="processing">{t("question.type_CHECKBOXS")}</Tag>
                  );
                if (v == "SIGN")
                  return <Tag color="orange">{t("question.type_SIGN")}</Tag>;
              }}
            />
            <Table.Column
              title={t("question.answers")}
              dataIndex="dataQuestion"
              render={(v, r: any) => {
                if (r.typeQuestion == "RANGES") {
                  return (
                    <>
                      <p>
                        {t("question.min")}: {_.get(v, "0.title")}{" "}
                      </p>
                      <p>
                        {t("question.max")}: {_.get(v, "1.title")}{" "}
                      </p>
                    </>
                  );
                }
                if (v) {
                  return (
                    <ul style={{ margin: 0, paddingLeft: 16 }}>
                      {v.map((a: any) => (
                        <li key={a.title}>{a.title}</li>
                      ))}
                    </ul>
                  );
                }
                return "";
              }}
            />
            {roleName == "company_manager" && record?.status == "INACTIVE" && (
              <Table.Column
                title={t("common.action")}
                align="center"
                width={150}
                render={(_, item: any) => (
                  <Space>
                    <ModalQuestion
                      mode="edit"
                      id={item.id}
                      questions={questions}
                      templateId={record?.id ?? 0}
                      buttonProps={{
                        icon: <EditOutlined />,
                        size: "small",
                      }}
                    />

                    <DeleteButton
                      recordItemId={item.id}
                      resource="question"
                      hideText
                      title={t("question.desc_delete")}
                      confirmTitle={t("question.desc_delete")}
                      confirmOkText={t("question.delete")}
                      confirmCancelText={t("question.cancel")}
                      size="small"
                      disabled={false}
                    />
                    <ModalQuestion
                      mode="clone"
                      id={item.id}
                      questions={questions}
                      templateId={record?.id ?? 0}
                      buttonProps={{
                        icon: <CopyOutlined />,
                        size: "small",
                      }}
                    >
                      {t("question.copy")}
                    </ModalQuestion>
                  </Space>
                )}
              />
            )}
          </Table>
          <br />

          {record && record.status == "INACTIVE" && (
            <ModalQuestion
              mode="create"
              templateId={record.id ?? ""}
              hidden={roleName != "company_manager"}
              questions={questions}
              buttonProps={{
                type: "dashed",
                icon: <PlusOutlined />,
              }}
            >
              {t("question.add_new")}
            </ModalQuestion>
          )}
        </Card>
      </Spin>
    </Show>
  );
};

const ModalQuestion = ({
  mode,
  templateId,
  id,
  children,
  hidden,
  buttonProps,
  questions,
}: {
  mode: "create" | "edit" | "clone";
  templateId: any;
  id?: number;
  hidden?: boolean;
  children?: JSX.Element | string;
  buttonProps?: ButtonProps;
  questions: IQuestion[];
}) => {
  const { form, formProps, show, close, modalProps } = useModalForm({
    resource: "question",
    action: mode,
  });
  const typeQuestion = Form.useWatch("typeQuestion", form);

  const t = useTranslate();

  useEffect(() => {
    if (mode == "clone" && formProps.initialValues?.sort) {
      form.setFieldValue("sort", questions.length + 1);
    }
  }, [mode, formProps.initialValues]);

  return (
    <>
      <Button
        {...buttonProps}
        hidden={hidden}
        onClick={() => {
          if (id) {
            show(id);
          } else {
            form.setFieldValue("sort", questions.length + 1);
            show();
          }
        }}
      >
        {children}
      </Button>

      <Modal
        {...modalProps}
        width={700}
        title={id ? t("question.edit_title") : t("question.create_title")}
        okText={id ? t("question.edit_save") : t("question.create_new")}
        cancelButtonProps={{ hidden: true }}
      >
        <Form
          {...formProps}
          layout="vertical"
          onFinish={(e: any) => {
            e.dataQuestion = e.dataQuestion || [];
            if (formProps.onFinish) {
              formProps.onFinish(e);
            }
          }}
        >
          <Form.Item name="templateId" hidden initialValue={templateId}>
            <Input maxLength={200} />
          </Form.Item>
          <Form.Item name="id" hidden>
            <Input maxLength={200} />
          </Form.Item>
          <Form.Item name="status" initialValue="ACTIVE" hidden>
            <Input maxLength={200} />
          </Form.Item>
          <Form.Item name="required" initialValue={true} hidden>
            <Input maxLength={200} />
          </Form.Item>
          <Form.Item
            label={t("question.order")}
            name="sort"
            rules={[
              {
                required: true,
                message: t("common.required"),
              },
            ]}
          >
            <InputNumber maxLength={3} />
          </Form.Item>
          <Form.Item
            label={t("question.name")}
            name="name"
            rules={[
              {
                required: true,
                message: t("common.required"),
              },
            ]}
          >
            <Input maxLength={200} />
          </Form.Item>

          <Form.Item label={t("question.desc")} name="description">
            <Input.TextArea rows={5} showCount maxLength={500} />
          </Form.Item>

          <Form.Item
            label={t("question.type")}
            name="typeQuestion"
            rules={[
              {
                required: true,
                message: t("common.required"),
              },
            ]}
          >
            <Select>
              <Select.Option value="NORMAL">
                {t("question.type_NORMAL")}
              </Select.Option>
              <Select.Option value="CAPTURE">
                {t("question.type_CAPTURE")}
              </Select.Option>
              <Select.Option value="QR_CODE">
                {t("question.type_QRCODE")}
              </Select.Option>
              <Select.Option value="TEXT_INPUT">
                {t("question.type_TEXT")}
              </Select.Option>
              <Select.Option value="RANGES">
                {t("question.type_RANGES")}
              </Select.Option>
              <Select.Option value="OPTIONS">
                {t("question.type_OPTIONS")}{" "}
              </Select.Option>
              <Select.Option value="CHECKBOXS">
                {t("question.type_CHECKBOXS")}
              </Select.Option>
              <Select.Option value="SIGN">
                {t("question.type_SIGN")}
              </Select.Option>
            </Select>
          </Form.Item>

          {typeQuestion == "RANGES" && (
            <Row gutter={[12, 12]}>
              <Col md={12}>
                <Form.Item
                  name={["dataQuestion", 0, "title"]}
                  label={t("question.min")}
                  dependencies={["dataQuestion", 1, "title"]}
                  rules={[
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        if (!value) {
                          return Promise.resolve();
                        }
                        if (
                          getFieldValue(["dataQuestion", 1, "title"]) <= value
                        ) {
                          return Promise.reject(t("common.less_max"));
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <InputNumber style={{ width: "100%" }} maxLength={200} />
                </Form.Item>
              </Col>
              <Col md={12}>
                <Form.Item
                  name={["dataQuestion", 1, "title"]}
                  label={t("question.max")}
                  dependencies={["dataQuestion", 0, "title"]}
                  rules={[
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        if (!value) {
                          return Promise.resolve();
                        }
                        if (
                          getFieldValue(["dataQuestion", 0, "title"]) >= value
                        ) {
                          return Promise.reject(t("common.more_min"));
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <InputNumber style={{ width: "100%" }} maxLength={200} />
                </Form.Item>
              </Col>
            </Row>
          )}

          {(typeQuestion == "OPTIONS" || typeQuestion == "CHECKBOXS") && (
            <div>
              <Typography.Title level={5}>
                {t("question.options")}
              </Typography.Title>
              <Form.Item
                name="dataQuestion"
                rules={[
                  {
                    required: true,
                    message: t("common.required"),
                  },
                ]}
              >
                <Form.List name="dataQuestion">
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map(({ key, name }, index) => (
                        <Row key={name} gutter={12}>
                          <Col span={20}>
                            <Form.Item
                              name={[name, "title"]}
                              label={t("question.answers")}
                              rules={[
                                {
                                  required: true,
                                  message: t("common.required"),
                                },
                              ]}
                            >
                              <Input maxLength={200} />
                            </Form.Item>
                          </Col>
                          <Col span={4}>
                            <br />
                            <Button
                              danger
                              type="text"
                              onClick={() => remove(name)}
                              style={{ marginTop: 10 }}
                            >
                              <DeleteOutlined />
                            </Button>
                          </Col>
                        </Row>
                      ))}
                      <Button
                        htmlType="button"
                        onClick={() => add()}
                        type="dashed"
                        icon={<PlusOutlined />}
                      >
                        {t("question.option_add")}
                      </Button>
                    </>
                  )}
                </Form.List>
              </Form.Item>
            </div>
          )}
          <Form.Item label={t("question.guide")} name="guide">
            <Editor />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};
