import { Select } from "antd";
import { useState } from "react";
export default function SelectCustom(props: any) {
  const [searchKey, setSearchKey] = useState("");

  return (
    <Select
      {...props}
      options={props.options.filter(
        (a: any) =>  a.label?.toLowerCase().indexOf(searchKey.toLowerCase()) > -1
      )}
      showSearch
      onSearch={(e) => {
        setSearchKey(e);
      }}
    />
  );
}
