import {
  Authenticated,
  NotificationProvider,
  Refine,
  useTranslate,
} from "@refinedev/core";
import { RefineKbar, RefineKbarProvider } from "@refinedev/kbar";

import {
  ErrorComponent,
  ThemedLayoutV2,
  // notificationProvider,
} from "@refinedev/antd";
import "@refinedev/antd/dist/reset.css";
import "./style.css";

import routerBindings, {
  CatchAllNavigate,
  DocumentTitleHandler,
  NavigateToResource,
  UnsavedChangesNotifier,
} from "@refinedev/react-router-v6";
import { ForgotPassword } from "pages/forgotPassword";
import { Login } from "pages/login";
import { useTranslation } from "react-i18next";
import { BrowserRouter, Link, Outlet, Route, Routes } from "react-router-dom";
import { authProvider } from "./authProvider";
import { dataProvider } from "dataProvider";
import { UserList } from "pages/user/List";
import { GroupUserList } from "pages/group_user/List";
import { UserCreate } from "pages/user/Create";
import { GroupUserCreate } from "pages/group_user/Create";
import { CompanyList } from "pages/company/List";
import { CompanyCreate } from "pages/company/Create";
import { TemplateList } from "pages/template/List";
import { TemplateCreate } from "pages/template/Create";
import { ConfigProvider, Space, Typography, notification } from "antd";
import { TemplateShow } from "pages/template/Detail";
import { AssignList } from "pages/assign/List";
import { AssignCreate } from "pages/assign/Create";
import { AssignShow } from "pages/assign/Detail";
import { PackageList } from "pages/package/List";
import { PackageCreate } from "pages/package/Create";
import { Header } from "components/header";
import { LocationList } from "pages/location/List";
import { LocationCreate } from "pages/location/Create";
import {
  DashboardOutlined,
  EnvironmentOutlined,
  FileProtectOutlined,
  HomeOutlined,
  ProfileOutlined,
  TableOutlined,
  UserOutlined,
  UsergroupAddOutlined,
} from "@ant-design/icons";
import { ActivityUserList } from "pages/activity_user/List";
import { ActivityUserDetail } from "pages/activity_user/Detail";
import { Dashboard } from "pages/dashboard/List";
import { Profile } from "pages/profile/List";
import { ActivityLocationList } from "pages/activity_location/List";
import { ActivityLocationDetail } from "pages/activity_location/Detail";
import { Sider } from "components/sider";
import { ResetPassword } from "pages/resetPassword";
import FirstPage from "pages/FirstPage";
import { ViewFile } from "pages/view-file";
import viVN from "antd/locale/vi_VN";
import { GlobalStyled } from "styles";

const notificationProvider: NotificationProvider = {
  open: ({ key, description, type }) => {
    if (!description) {
      return;
    }
    notification.open({
      key,
      message: description ?? null,
      type: type == "error" ? "error" : "success",
      // className: "custom-noti",
      placement: "bottom",
      // icon: type == "error" ? <ErrorIcon /> : <DoneNotiIcon />,
    });
    // }
  },
  close: (key) => notification.destroy(key),
};

function App() {
  const { t: trans, i18n } = useTranslation();

  const t = useTranslate();

  const i18nProvider = {
    translate: (key: string, params: object) => trans(key, params),
    changeLocale: (lang: string) => i18n.changeLanguage(lang),
    getLocale: () => i18n.language,
  };

  console.log(i18n.getResourceBundle("en", "common"));

  return (
    <ConfigProvider locale={viVN}>
      <BrowserRouter>
        <RefineKbarProvider>
          <GlobalStyled />
          <Refine
            dataProvider={dataProvider()}
            notificationProvider={notificationProvider}
            routerProvider={routerBindings}
            authProvider={authProvider}
            i18nProvider={i18nProvider}
            accessControlProvider={
              {
                can: async ({
                  resource,
                  action,
                }: {
                  resource: string;
                  action: string;
                }) => {
                  const roleName = await localStorage.getItem("ROLE_NAME");
                  let can = false;
                  if (roleName === "supper_admin") {
                    if (
                      ["profile", "view-file", "company", "package"].includes(
                        resource
                      )
                    ) {
                      can = true;
                    }
                  }
                  if (roleName === "company_admin") {
                    if (
                      [
                        "view-file",
                        "profile",
                        "dashboard",
                        "company_manager",
                        "template",
                        "location",
                      ].includes(resource)
                    ) {
                      can = true;
                    }
                  }
                  if (roleName === "company_manager") {
                    if (
                      [
                        "view-file",
                        "profile",
                        "dashboard",
                        "activity_location",
                        "activity_user",
                        "assign",
                        "template",
                        "group_user",
                      ].includes(resource)
                    ) {
                      can = true;
                    }
                  }
                  return { can };
                },
              } as any
            }
            resources={[
              {
                name: "dashboard",
                list: "/dashboard",
                meta: {
                  canDelete: true,
                  label: t("menu.dasboard"),
                  // label: "Dashboard",
                  icon: <DashboardOutlined />,
                },
              },

              {
                name: "company_manager",
                list: "/company_manager",
                create: "/company_manager/create",
                edit: "/company_manager/edit/:id",
                show: "/company_manager/show/:id",
                meta: {
                  canDelete: true,
                  icon: <UserOutlined />,
                  label: t("menu.user"),
                  // label: t("menu_user"),
                  // label: "Quản lý người dùng",
                },
              },
              {
                name: "package",
                list: "/package",
                create: "/package/create",
                edit: "/package/edit/:id",
                show: "/package/show/:id",
                meta: {
                  canDelete: true,
                  label: t("menu.package"),
                  icon: <FileProtectOutlined />,
                },
              },
              {
                name: "company",
                list: "/company",
                create: "/company/create",
                edit: "/company/edit/:id",
                show: "/company/show/:id",
                meta: {
                  canDelete: true,
                  label: t("menu.company"),
                  icon: <HomeOutlined />,
                },
              },

              {
                name: "location",
                list: "/location",
                create: "/location/create",
                edit: "/location/edit/:id",
                show: "/location/show/:id",
                meta: {
                  label: t("menu.location"),
                  icon: <EnvironmentOutlined />,
                  canDelete: true,
                },
              },

              {
                name: "assign",
                list: "/assign",
                create: "/assign/create",
                edit: "/assign/edit/:id",
                show: "/assign/show/:id",
                meta: {
                  label: t("menu.assign"),
                  icon: <ProfileOutlined />,
                  canDelete: true,
                },
              },

              {
                name: "activity_user",
                list: "/activity_user",
                // create: "/activity_iser/create",
                edit: "/activity_user/edit/:id",
                show: "/activity_user/show/:id",
                meta: {
                  canDelete: true,
                  // label: t("menu_activity_user"),
                  label: t("menu.activity_user"),
                  icon: <UserOutlined />,
                },
              },
              {
                name: "activity_location",
                list: "/activity_location",
                // create: "/activity_iser/create",
                edit: "/activity_location/edit/:id",
                show: "/activity_location/show/:id",
                meta: {
                  canDelete: true,
                  // label: t("menu_activity_location"),
                  label: t("menu.activity_location"),
                  icon: <EnvironmentOutlined />,
                },
              },
              {
                name: "template",
                list: "/template",
                create: "/template/create",
                edit: "/template/edit/:id",
                show: "/template/show/:id",
                meta: {
                  label: t("menu.template"),
                  // label: t("menu_template"),
                  icon: <TableOutlined />,
                  canDelete: true,
                },
              },

              {
                name: "group_user",
                list: "/group_user",
                create: "/group_user/create",
                edit: "/group_user/edit/:id",
                show: "/group_user/show/:id",
                meta: {
                  canDelete: true,
                  label: t("menu.group_user"),
                  icon: <UsergroupAddOutlined />,
                  // label: "Danh sách nhóm",
                },
              },
            ]}
            options={{
              syncWithLocation: true,
              warnWhenUnsavedChanges: true,
              projectId: "I2MLyc-ClSmLw-HPf61N",
            }}
          >
            <Routes>
              <Route
                element={
                  <Authenticated fallback={<CatchAllNavigate to="/login" />}>
                    <ThemedLayoutV2
                      Header={() => <Header />}
                      Title={({ collapsed }: any) => (
                        <Link to="/">
                          <Space size="small">
                            <img src="/images/logo.svg" height={32} />

                            {!collapsed && (
                              <Typography.Title
                                level={5}
                                style={{
                                  margin: 0,
                                  fontSize: 18,
                                  fontWeight: "bold",
                                }}
                              >
                                CheckYay
                              </Typography.Title>
                            )}
                          </Space>
                        </Link>
                      )}
                      Sider={Sider}
                    >
                      <Outlet />
                    </ThemedLayoutV2>
                  </Authenticated>
                }
              >
                <Route
                  index
                  element={<FirstPage />}
                  // element={<NavigateToResource resource="dashboard" />}
                />

                {/* for super admin */}
                <Route path="/company">
                  <Route index element={<CompanyList />} />
                  <Route path="create" element={<CompanyCreate />} />
                  <Route path="edit/:id" element={<CompanyCreate />} />
                  <Route path="show/:id" element={<CompanyCreate />} />
                </Route>
                <Route path="/package">
                  <Route index element={<PackageList />} />
                  <Route path="create" element={<PackageCreate />} />
                  <Route path="edit/:id" element={<PackageCreate />} />
                  <Route path="show/:id" element={<PackageCreate />} />
                </Route>
                <Route path="/location">
                  <Route index element={<LocationList />} />
                  <Route path="create" element={<LocationCreate />} />
                  <Route path="edit/:id" element={<LocationCreate />} />
                  <Route path="show/:id" element={<LocationCreate />} />
                </Route>

                {/* for super company admin */}

                <Route path="/template">
                  <Route index element={<TemplateList />} />
                  <Route path="create" element={<TemplateCreate />} />
                  <Route path="edit/:id" element={<TemplateCreate />} />
                  <Route path="show/:id" element={<TemplateShow />} />
                </Route>

                {/* for super company admin */}

                <Route path="/company_manager">
                  <Route index element={<UserList />} />
                  <Route path="create" element={<UserCreate />} />
                  <Route path="edit/:id" element={<UserCreate />} />
                  <Route path="show/:id" element={<UserCreate />} />
                </Route>

                <Route path="/group_user">
                  <Route index element={<GroupUserList />} />
                  <Route path="create" element={<GroupUserCreate />} />
                  <Route path="edit/:id" element={<GroupUserCreate />} />
                  <Route path="show/:id" element={<GroupUserCreate />} />
                </Route>

                <Route path="/assign">
                  <Route index element={<AssignList />} />
                  <Route path="create" element={<AssignCreate />} />
                  <Route path="edit/:id" element={<AssignCreate />} />
                  <Route path="show/:id" element={<AssignShow />} />
                </Route>

                <Route path="/activity_user">
                  <Route index element={<ActivityUserList />} />
                  <Route path="show/:id" element={<ActivityUserDetail />} />
                </Route>
                <Route path="/activity_location">
                  <Route index element={<ActivityLocationList />} />
                  <Route path="show/:id" element={<ActivityLocationDetail />} />
                </Route>

                <Route path="/dashboard">
                  <Route index element={<Dashboard />} />
                </Route>

                <Route path="/profile">
                  <Route index element={<Profile />} />
                </Route>
                <Route path="/viewfile">
                  <Route index element={<ViewFile />} />
                </Route>

                <Route path="*" element={<ErrorComponent />} />
              </Route>
              <Route
                element={
                  <Authenticated fallback={<Outlet />}>
                    <NavigateToResource />
                  </Authenticated>
                }
              >
                <Route path="/login" element={<Login />} />
                {/* <Route path="/register" element={<Register />} /> */}
                <Route path="/forgot-password" element={<ForgotPassword />} />
                <Route path="/reset-password" element={<ResetPassword />} />
              </Route>
            </Routes>

            <RefineKbar />
            <UnsavedChangesNotifier />
            <DocumentTitleHandler
              handler={(e) => {
                return "CheckYay app";
              }}
            />
          </Refine>
        </RefineKbarProvider>
      </BrowserRouter>
    </ConfigProvider>
  );
}

export default App;
