import React from "react";
import { IResourceComponentsProps, useTranslate } from "@refinedev/core";
import { Create, useForm, useSelect } from "@refinedev/antd";
import { Form, Input, Select, Checkbox, DatePicker, Row, Col } from "antd";
import dayjs from "dayjs";
import UploadFile from "components/image";
import SelectCustom from "components/select";
import _ from "lodash";

export const GroupUserCreate: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();
  const { formProps, saveButtonProps, onFinish, form } = useForm({
    onMutationError(res) {
      const errors = _.get(res, "response.data.messages") || [];
      if (errors.length) {
        const fields = errors.map((e: any) => ({
          name: e.key,
          errors: [e.msg],
        }));
        form.setFields(fields);
        form.scrollToField(fields[0].name, {
          behavior: "smooth",
          block: "center",
        });
      }
    },
  });

  const { selectProps: userSelectProps } = useSelect({
    resource: "user",
    optionValue: "userId",
    optionLabel: "fullName",
    pagination: {
      pageSize: 10000,
    },
  });
  const isEdit = formProps.initialValues?.id;
  return (
    <Create
      saveButtonProps={{ ...saveButtonProps, children: t('group.save')}}
      breadcrumb={null}
      title={isEdit ? t('group.edit_title') : t('group.create_title')}
      footerButtonProps={{
        style: { float: "left", paddingLeft: "calc(25% + 12px)" },
      }}
    >
      <Form
        {...formProps}
        layout="vertical"
        onFinish={(d: any) => {
          d.isActive = true;
          onFinish(d);
        }}
      >
        <Form.Item name={["id"]} hidden>
          <Input disabled />
        </Form.Item>
        <Row gutter={[12, 0]}>
          <Col span={6}>
            {/* <Form.Item label="Hình" name={"logo"}>
              <UploadFile />
            </Form.Item> */}
          </Col>
          <Col span={12}>
            <Form.Item
              label={t('group.name')}
              name={"name"}
              rules={[
                {
                  required: true,
                  message:t('common.required')
                },
              ]}
            >
              <Input maxLength={200} />
            </Form.Item>
            <Form.Item
              label={t('group.user_list')}
              name={"userIds"}
              rules={[
                {
                  required: true,
                  message:t('common.required')
                },
              ]}
            >
              <SelectCustom {...userSelectProps} mode="multiple" />
            </Form.Item>
            <Form.Item label={t('group.note')} name={"description"}>
              <Input.TextArea rows={5} showCount maxLength={200} />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Create>
  );
};
