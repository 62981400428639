import {
  LoginFormTypes,
  useLink,
  useActiveAuthProvider,
  useTranslate,
} from "@refinedev/core";
import { Form, Input, Button, Checkbox, Carousel } from "antd";
import { useLogin } from "@refinedev/core";

import styled from "styled-components";
import {
  LockOutlined,
  MailOutlined,
  RedEnvelopeOutlined,
} from "@ant-design/icons";

export const Login = () => {
  const t= useTranslate()
  return (
    <Styled.Wrap>
      <Styled.Left>
        <Carousel autoplay>
          <Styled.Slide src="/images/carousel_1.jpg" />
          <Styled.Slide src="/images/carousel_2.jpg" />
          <Styled.Slide src="/images/carousel_3.jpg" />
          <Styled.Slide src="/images/carousel_4.jpg" />
          <Styled.Slide src="/images/carousel_5.jpg" />
        </Carousel>
      </Styled.Left>
      <Styled.Right>
        <Styled.Form>
          <Styled.Logo>
            <img src="/images/logo.svg" />
          </Styled.Logo>
          <Styled.Title>{t("login.title")}</Styled.Title>
          <FormSection />
        </Styled.Form>
      </Styled.Right>
    </Styled.Wrap>
  );
};

const FormSection = () => {
  const [form] = Form.useForm<LoginFormTypes>();
  const Link = useLink();
  const authProvider = useActiveAuthProvider();
  const { mutate: login, isLoading } = useLogin<LoginFormTypes>({
    v3LegacyAuthProviderCompatible: Boolean(authProvider?.isLegacy),
  });
  const t = useTranslate();
  return (
    <Form<LoginFormTypes>
      layout="vertical"
      form={form}
      onFinish={(values) => login(values)}
      requiredMark={false}
      initialValues={{
        remember: false,
      }}
    >
      <Form.Item
        name="email"
        rules={[
          { required: true, message: t("common.required") },
          {
            type: "email",
            message: t("common.email_required"),
          },
        ]}
      >
        <Input
          prefix={<MailOutlined style={{ color: "#1890ff" }} />}
          placeholder={t("login.email")}
        />
      </Form.Item>
      <Form.Item
        name="password"
        rules={[{ required: true, message: t("common.required") }]}
      >
        <Input
          prefix={<LockOutlined style={{ color: "#1890ff" }} />}
          type="password"
          placeholder={t("login.password")}
        />
      </Form.Item>

      <Form.Item name="remember" valuePropName="checked">
        <Checkbox>{t('login.remmeber')}</Checkbox>
      </Form.Item>

      <Form.Item>
        <Button
          type="primary"
          size="large"
          htmlType="submit"
          loading={isLoading}
          block
          style={{ borderRadius: 4 }}
        >
          {t('login.button')}
        </Button>
      </Form.Item>
      <Styled.ButtonText>
        <Link to="/forgot-password">{t('login.forgot_password')}</Link>
      </Styled.ButtonText>
    </Form>
  );
};
export const Styled = {
  ButtonText: styled.div`
    text-align: center;

    a {
      color: var(--primary-6, #1890ff);
      text-align: center;
      font-size: 16px;
      line-height: 24px; /* 150% */
    }
  `,
  Logo: styled.div`
    text-align: center;
    margin-bottom: 40px;
  `,
  Form: styled.div`
    input {
      width: 330px;
      height: 40px;
      padding: 0 12px;
      align-items: center;
      border-radius: 2px;
      border: 1px solid var(--neutral-5, #d9d9d9);
      background: var(--neutral-1, #fff);
    }
  `,
  Title: styled.h3`
    color: var(--character-title-85, rgba(0, 0, 0, 0.85));
    text-align: center;
    font-size: 33px;
    text-align: center;
    font-style: normal;
    font-weight: 600;
    line-height: 38px; /* 115.152% */
    letter-spacing: 0.165px;
    margin-bottom: 40px;
  `,
  Wrap: styled.div`
    height: 100vh;
    display: flex;
    width: 100%;
  `,
  Right: styled.div`
    width: 50%;
    display: flex;
    height: 100vh;

    display: flex;
    align-items: center;
    justify-content: center;
  `,
  Left: styled.div`
    height: 100vh;
    width: 50%;
  `,
  Slide: styled.img`
    width: 100%;
    height: 100vh;
    object-fit: cover;
  `,
};
