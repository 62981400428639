import React from "react";
import {
  IResourceComponentsProps,
  useGetIdentity,
  useTranslate,
} from "@refinedev/core";
import { Create, useForm, useModalForm, useSelect } from "@refinedev/antd";
import { Button, Col, Form, Input, Modal, Row, Select } from "antd";
import { IUser } from "types";
import UploadFile from "components/image";
import { LockOutlined } from "@ant-design/icons";
import _ from "lodash";

export const UserCreate: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();
  const { formProps, saveButtonProps, form, onFinish } = useForm({
    onMutationError(res) {
      const errors = _.get(res, "response.data.messages") || [];

      if (errors.length) {
        const fields = errors.map((e: any) => ({
          name: e.key,
          errors: [e.msg],
        }));
        form.setFields(fields);
        form.scrollToField(fields[0].name, {
          behavior: "smooth",
          block: "center",
        });
      }
    },
  });

  const { data: user } = useGetIdentity<IUser>();

  React.useEffect(() => {
    if (user?.companyCode) {
      form.setFieldValue("companyCode", user?.companyCode);
    }
  }, [user?.companyCode]);

  const isEdit = formProps.initialValues?.id;

  const {
    formProps: passwordFormProps,
    modalProps,
    show,
  } = useModalForm({
    resource: "company_manager",
    action: "create",
    meta: {
      type: "update_password",
    },
  });

  const permisionType = Form.useWatch("permisionType", form);
  return (
    <Create
      saveButtonProps={{
        ...saveButtonProps,
        children: isEdit ? t("user.save") : t("user.create"),
      }}
      footerButtonProps={{
        style: { float: "left", paddingLeft: "calc(25% + 12px)" },
      }}
      breadcrumb={null}
      title={isEdit ? t("user.edit_title") : t("user.create_title")}
      headerButtons={
        isEdit ? (
          <Button
            onClick={() => {
              passwordFormProps.form?.setFieldValue(
                "userId",
                formProps.initialValues?.userId
              );
              show();
            }}
            icon={<LockOutlined />}
          >
            {t("user.change_password")}
          </Button>
        ) : null
      }
    >
      <Form
        {...formProps}
        layout="vertical"
        initialValues={{
          ...formProps.initialValues,
          phone: formProps.initialValues?.phone?.replace("+84", ""),
        }}
        onFinish={(e: any) => {
          onFinish({ ...e, phone: "+84" + e.phone });
        }}
      >
        <Form.Item name={["id"]} hidden>
          <Input disabled />
        </Form.Item>
        <Form.Item name={["status"]} hidden initialValue="ACTIVE">
          <Input disabled />
        </Form.Item>
        <Form.Item name={["companyCode"]} hidden>
          <Input disabled />
        </Form.Item>
        <Row gutter={[12, 0]}>
          <Col span={6}></Col>
          <Col span={12}>
            <Form.Item
              label={t("user.full_name")}
              name={["fullName"]}
              rules={[
                {
                  required: true,
                  message: t("common.required"),
                },
              ]}
            >
              <Input maxLength={200} placeholder={t("user.full_name")} />
            </Form.Item>{" "}
            <Form.Item
              label={t("user.role")}
              name={["permisionType"]}
              rules={[
                {
                  required: true,
                  message: t("common.required"),
                },
              ]}
            >
              <Select
                placeholder={t("user.select_role")}
                options={[
                  {
                    value: "STAFF",
                    label: t("user.role_staff"),
                  },
                  {
                    value: "MANAGER",
                    label: t("user.role_manager"),
                  },
                ]}
              />
            </Form.Item>
            <Form.Item
              label={t("user.phone")}
              name={"phone"}
              rules={[
                {
                  required: true,
                  message: t("common.required"),
                },
              ]}
            >
              <Input addonBefore="+84" placeholder={t("user.phone")} />
            </Form.Item>
            <Form.Item
              label={t("user.email")}
              name={["email"]}
              rules={
                permisionType == "MANAGER"
                  ? [
                    {
                      required: true,
                      message: t("common.required"),
                    },
                  ]
                  : []
              }
            >
              <Input
                maxLength={200}
                placeholder={t("user.email")}
                autoComplete="one-time-code"
              />
            </Form.Item>
            {!isEdit && (
              <>
                <Form.Item
                  label={t("user.password")}
                  name={["password"]}
                  rules={[
                    {
                      required: isEdit ? false : true,
                      message: t("common.required"),
                    },
                    {
                      validator(rule, value, callback) {
                        if (!value) Promise.resolve();
                        if (value?.length < 6) {
                          return Promise.reject(t("common.password_620"));
                        }
                        if (!/^(?=.*[a-z])(?=.*[A-Z]).+$/.test(value)) {
                          return Promise.reject(t("common.password_character"));
                        }
                        if (
                          value?.indexOf(" ") > -1 ||
                          !/^[^\s\u00C0-\u024F]+$/.test(value)
                        ) {
                          return Promise.reject(t("common.password_space"));
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                  <Input.Password
                    maxLength={200}
                    autoComplete="one-time-code"
                    placeholder={t("user.password")}
                  />
                </Form.Item>
                <Form.Item
                  dependencies={["password"]}
                  hidden={isEdit}
                  label={t("user.password_confirm")}
                  name={["password_confirm"]}
                  rules={[
                    {
                      required: isEdit ? false : true,
                      message: t("common.required"),
                    },
                    ({ getFieldValue }) => ({
                      validator(rule, value) {
                        if (!value) {
                          return Promise.resolve();
                        }
                        if (getFieldValue("password") != value) {
                          return Promise.reject(t("common.password_same"));
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <Input.Password
                    autoComplete="one-time-code"
                    placeholder={t("user.password_confirm")}
                    maxLength={200}
                  />
                </Form.Item>
              </>
            )}
            <Form.Item label={t("user.note")} name={["description"]}>
              <Input.TextArea
                showCount
                rows={5}
                maxLength={200}
                placeholder={t("user.note")}
              />
            </Form.Item>
            <Form.Item
              style={{ width: 90 }}
              label={t("user.avatar")}
              name={["avatar"]}
            >
              <UploadFile />
            </Form.Item>
          </Col>
        </Row>
      </Form>

      <Modal
        {...modalProps}
        title={t("user.change_password")}
        width={400}
        okText={t("passsword.save")}
        cancelText={t("passsword.close")}
      >
        <Form {...passwordFormProps} layout="vertical">
          <Form.Item name={["userId"]} hidden>
            <Input />
          </Form.Item>
          <Form.Item
            label={t("user.password")}
            name={["password"]}
            rules={[
              {
                required: isEdit ? false : true,
                message: t("common.required"),
              },
              {
                validator(rule, value, callback) {
                  if (!value) Promise.resolve();
                  if (value?.length < 6) {
                    return Promise.reject(t("common.password_620"));
                  }
                  if (!/^(?=.*[a-z])(?=.*[A-Z]).+$/.test(value)) {
                    return Promise.reject(t("common.password_character"));
                  }
                  if (
                    value?.indexOf(" ") > -1 ||
                    !/^[^\s\u00C0-\u024F]+$/.test(value)
                  ) {
                    return Promise.reject(t("common.password_space"));
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Input.Password
              maxLength={200}
              autoComplete="one-time-code"
              placeholder={t("user.password")}
            />
          </Form.Item>
          <Form.Item
            dependencies={["password"]}
            hidden={isEdit}
            label={t("user.password_confirm")}
            name={["password_confirm"]}
            rules={[
              {
                required: isEdit ? false : true,
                message: t("common.required"),
              },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (!value) {
                    return Promise.resolve();
                  }
                  if (getFieldValue("password") != value) {
                    return Promise.reject(t("common.password_same"));
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input.Password
              autoComplete="one-time-code"
              placeholder={t("user.password_confirm")}
              maxLength={200}
            />
          </Form.Item>
        </Form>
      </Modal>
    </Create>
  );
};
