import React from "react";
import { IResourceComponentsProps, useTranslate } from "@refinedev/core";
import { Create, useForm } from "@refinedev/antd";
import { Form, Input, DatePicker, Row, Col } from "antd";
import dayjs from "dayjs";
import _ from "lodash";

export const TemplateCreate: React.FC<IResourceComponentsProps> = () => {
  const { formProps, saveButtonProps, queryResult, form } = useForm({
    redirect: "show",
    onMutationError(res) {
      const errors = _.get(res, "response.data.messages") || [];
      if (errors.length) {
        const fields = errors.map((e: any) => ({
          name: e.key,
          errors: [e.msg],
        }));
        form.setFields(fields);
        form.scrollToField(fields[0].name, {
          behavior: "smooth",
          block: "center",
        });
      }
    },
  });
  const isEdit = !!queryResult?.data?.data?.id;

  const t = useTranslate();

  return (
    <Create
      isLoading={queryResult?.isFetching}
      saveButtonProps={{
        ...saveButtonProps,
        children: isEdit ? t("template.save_edit") : t("template.save_create"),
      }}
      footerButtonProps={{
        style: { float: "left", paddingLeft: "calc(25% + 12px)" },
      }}
      breadcrumb={null}
      title={isEdit ? t("template.title_edit") : t("template.title_create")}
    >
      <Form
        {...formProps}
        layout="vertical"
        initialValues={{
          ...formProps?.initialValues,
          startTime: dayjs(formProps?.initialValues?.startTime),
          endTime: dayjs(formProps?.initialValues?.endTime),
        }}
      >
        <Form.Item name={["id"]} hidden>
          <Input disabled />
        </Form.Item>
        <Row gutter={12}>
          <Col span={6} />
          <Col span={12}>
            <Row gutter={12}>
              <Col span={24}>
                <Form.Item
                  label={t("template.name")}
                  name={["name"]}
                  rules={[
                    {
                      required: true,
                      message: t("common.required"),
                    },
                  ]}
                >
                  <Input maxLength={200} />
                </Form.Item>

                <Form.Item label={t("template.note")} name={["description"]}>
                  <Input.TextArea showCount rows={5} maxLength={200} />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item name={["status"]} hidden initialValue="INACTIVE">
                  <Input maxLength={200} />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
    </Create>
  );
};
