import React, { useEffect } from "react";
import { IResourceComponentsProps, useTranslate } from "@refinedev/core";
import { Create, useForm, useModalForm, useSelect } from "@refinedev/antd";
import {
  Form,
  Input,
  DatePicker,
  Row,
  Col,
  Typography,
  Modal,
  Button,
} from "antd";
import dayjs from "dayjs";
import UploadFile from "components/image";

import advancedFormat from "dayjs/plugin/advancedFormat";
import customParseFormat from "dayjs/plugin/customParseFormat";
import localeData from "dayjs/plugin/localeData";
import weekday from "dayjs/plugin/weekday";
import weekOfYear from "dayjs/plugin/weekOfYear";
import weekYear from "dayjs/plugin/weekYear";
import { EditOutlined, LockOutlined } from "@ant-design/icons";
import SelectCustom from "components/select";
import _ from "lodash";
import axios from "axios";

const api_key = "HjSQHVJJqNDgexVJQLq89MEg7hM8jWV1EpMfrZMc";

dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);
dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(weekOfYear);
dayjs.extend(weekYear);

export const CompanyCreate: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate();
  const { formProps, saveButtonProps, form, onFinish } = useForm({
    onMutationError(res) {
      const errors = _.get(res, "response.data.messages") || [];
      if (errors.length) {
        const fields = errors.map((e: any) => ({
          name: e.key,
          errors: [e.msg],
        }));
        form.setFields(fields);
        form.scrollToField(fields[0].name, {
          behavior: "smooth",
          block: "center",
        });
      }
    },
  });

  const { selectProps } = useSelect({
    resource: "package",
    optionLabel: "name",
    pagination: {
      pageSize: 10000,
    },
    filters: [
      {
        field: "status",
        value: "ACTIVE",
        operator: "eq",
      },
    ],
  });

  const {
    formProps: passwordFormProps,
    modalProps: passwordModalProps,
    show: passwordShow,
  } = useModalForm({
    resource: "company_admin",
    action: "create",
    warnWhenUnsavedChanges: false,
    meta: {
      type: "update_password",
    },
    onMutationError(res) {
      const errors = _.get(res, "response.data.messages") || [];
      if (errors.length) {
        const fields = errors.map((e: any) => ({
          name: e.key,
          errors: [e.msg],
        }));
        passwordFormProps.form?.setFields(fields);
        passwordFormProps.form?.scrollToField(fields[0].name, {
          behavior: "smooth",
          block: "center",
        });
      }
    },
  });

  const {
    formProps: infoFormProps,
    modalProps: infoModalProps,
    show: infoShow,
  } = useModalForm({
    resource: "company_admin",
    action: "edit",
    warnWhenUnsavedChanges: false,
    onMutationSuccess(res) {
      window.location.reload();
    },
    onMutationError(res) {
      const errors = _.get(res, "response.data.messages") || [];
      if (errors.length) {
        const fields = errors.map((e: any) => ({
          name: e.key,
          errors: [e.msg],
        }));
        infoFormProps.form?.setFields(fields);
        infoFormProps.form?.scrollToField(fields[0].name, {
          behavior: "smooth",
          block: "center",
        });
      }
    },
  });
  const address = Form.useWatch("address", form);
  const handleGeocode = async (address = "") => {
    const r = await axios.get(
      `https://rsapi.goong.io/Place/AutoComplete?input=${address}&api_key=${api_key}`
    );
    const place_id = _.get(r, "data.predictions[0].place_id");
    if (place_id) {
      const r2 = await axios.get(
        `https://rsapi.goong.io/Place/Detail?place_id=${place_id}&api_key=${api_key}`
      );

      formProps.form?.setFieldValue(
        "lat",
        _.get(r2, "data.result.geometry.location.lat")
      );

      formProps.form?.setFieldValue(
        "long",
        _.get(r2, "data.result.geometry.location.lng")
      );
    }
  };

  const isEdit = !!formProps.initialValues?.id;
  return (
    <Create
      saveButtonProps={{
        ...saveButtonProps,
        children: isEdit ? t("company.save_edit") : t("company.save_create"),
      }}
      title={isEdit ? t("company.title_edit") : t("company.title_create")}
      breadcrumb={null}
      headerButtons={<></>}
      footerButtonProps={{
        style: { float: "left", paddingLeft: "calc(25% + 12px)" },
      }}
    >
      <Form
        {...formProps}
        layout="vertical"
        initialValues={{
          ...formProps.initialValues,
          activeStartTime: dayjs(formProps.initialValues?.activeStartTime),
          activeEndTime: dayjs(formProps.initialValues?.activeEndTime),
          emailAdmin: formProps?.initialValues?.user?.email,
          phone: formProps.initialValues?.phone?.replace("+84", ""),
          phoneAdmin: formProps.initialValues?.user?.phone?.replace("+84", ""),
        }}
        onFinish={(d: any) => {
          d.phone = "+84" + d.phone;
          d.phoneAdmin = "+84" + d.phoneAdmin;
          onFinish(d);
        }}
      >
        <Form.Item name={["lat"]} hidden>
          <Input disabled />
        </Form.Item>
        <Form.Item name={["long"]} hidden>
          <Input disabled />
        </Form.Item>
        <Form.Item name={["id"]} hidden>
          <Input disabled />
        </Form.Item>
        <Form.Item name={["status"]} initialValue="ACTIVE" hidden>
          <Input disabled />
        </Form.Item>
        <Row gutter={[12, 0]}>
          <Col span={6} />
          <Col span={12}>
            <Row gutter={[12, 0]}>
              <Col span={24}>
                <Typography.Title level={5}>
                  {t("company.info")}
                </Typography.Title>
              </Col>
              <Col span={8}>
                <Form.Item
                  label={t("company.code")}
                  name={["companyCode"]}
                  rules={[
                    {
                      required: true,
                      message: t("common.required"),
                    },

                    {
                      validator(rule, value, callback) {
                        if (value?.indexOf(" ") > -1) {
                          return Promise.reject(t("company.code_nospace"));
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                  <Input maxLength={12} disabled={isEdit} />
                </Form.Item>
              </Col>
              <Col span={16}>
                <Form.Item
                  label={t("company.name")}
                  name={["name"]}
                  rules={[
                    {
                      required: true,
                      message: t("common.required"),
                    },
                  ]}
                >
                  <Input maxLength={200} placeholder={t("company.name")} />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label={t("company.package")}
                  name={["packageId"]}
                  rules={[
                    {
                      required: true,
                      message: t("common.required"),
                    },
                  ]}
                >
                  <SelectCustom
                    {...selectProps}
                    placeholder={t("company.package")}
                    showSearch
                    onSearch={() => { }}
                  />
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item
                  label={t("company.active_time")}
                  name={["activeStartTime"]}
                  dependencies={["activeEndTime"]}
                  rules={[
                    {
                      required: true,
                      message: t("common.required"),
                    },
                    {
                      validator(rule, value, callback) {
                        if (!value) {
                          return;
                        }
                        if (
                          dayjs(value).format("YYYYMMDD") >
                          dayjs(form.getFieldValue("activeEndTime")).format(
                            "YYYYMMDD"
                          )
                        ) {
                          return Promise.reject(
                            t("company.active_time_validate")
                          );
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                  <DatePicker
                    style={{ width: "100%" }}
                    placeholder={t("company.active_time_select")}
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  label={t("company.exp_time")}
                  dependencies={["activeStartTime"]}
                  name={["activeEndTime"]}
                  rules={[
                    {
                      required: true,
                      message: t("common.required"),
                    },
                    {
                      validator(rule, value, callback) {
                        if (!value) {
                          return;
                        }
                        if (
                          dayjs(value).format("YYYYMMDD") <
                          dayjs(form.getFieldValue("activeStartTime")).format(
                            "YYYYMMDD"
                          )
                        ) {
                          return Promise.reject(t("company.exp_time_validate"));
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                  <DatePicker
                    placeholder={t("company.exp_time_select")}
                    style={{ width: "100%" }}
                  />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item
                  label={t("company.phone")}
                  name={["phone"]}
                  rules={[
                    {
                      required: true,
                      message: t("common.required"),
                    },
                  ]}
                >
                  <Input
                    autoComplete="one-time-code"
                    maxLength={200}
                    placeholder={t("company.phone")}
                    addonBefore="+84"
                  />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item label={t("company.website")} name={["website"]}>
                  <Input maxLength={200} placeholder={t("company.website")} />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item
                  label={t("company.address")}
                  name={["address"]}
                  rules={[
                    {
                      required: true,
                      message: t("common.required"),
                    },
                  ]}
                >
                  <Input
                    maxLength={200}
                    placeholder={t("company.address")}
                    onBlur={() => {
                      handleGeocode(address);
                    }}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item label={t("company.note")} name={["description"]}>
                  <Input.TextArea
                    showCount
                    rows={5}
                    maxLength={200}
                    placeholder={t("company.note")}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item label={t("company.logo")} name={["logo"]}>
                  <UploadFile />
                </Form.Item>
              </Col>
              <Col span={19} />

              {isEdit ? (
                <>
                  <Col span={8}>
                    <Typography.Title level={5}>
                      {t("company.admin")}
                    </Typography.Title>
                  </Col>
                  <Col span={16} style={{ textAlign: "right" }}>
                    <Button
                      type="text"
                      onClick={() => {
                        passwordFormProps.form?.setFieldsValue({
                          userId: formProps.initialValues?.user?.userId,
                        });
                        passwordShow();
                      }}
                      icon={<LockOutlined />}
                    >
                      {t("company.update_password")}
                    </Button>

                    <Button
                      type="text"
                      onClick={() => {
                        infoShow(formProps.initialValues?.user?.id);
                      }}
                      icon={<EditOutlined />}
                    >
                      {t("company.update_account")}
                    </Button>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      label={t("company.admin_email")}
                      name={["emailAdmin"]}
                    >
                      <Input maxLength={200} disabled />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      label={t("company.admin_phone")}
                      name={["phoneAdmin"]}
                    >
                      <Input maxLength={200} disabled addonBefore="+84" />
                    </Form.Item>
                  </Col>
                </>
              ) : (
                <>
                  <Col span={24}>
                    <Typography.Title level={5}>
                      {t("company.admin")}
                    </Typography.Title>
                  </Col>

                  <Col span={24}>
                    <Form.Item
                      label={t("company.admin_email")}
                      name={["emailAdmin"]}
                      rules={[
                        {
                          required: true,
                          message: t("common.required"),
                        },
                        {
                          type: "email",
                          message: t("common.wrong_email"),
                        },
                      ]}
                    >
                      <Input
                        autoComplete="one-time-code"
                        maxLength={200}
                        placeholder={t("company.admin_email")}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      label={t("company.admin_phone")}
                      name={["phoneAdmin"]}
                      rules={[
                        {
                          required: true,
                          message: t("common.required"),
                        },
                        {
                          min: 8,
                          max: 11,
                        },
                      ]}
                    >
                      <Input
                        autoComplete="one-time-code"
                        maxLength={200}
                        addonBefore="+84"
                        placeholder={t("company.admin_phone")}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      label={t("user.password")}
                      name={["password"]}
                      rules={[
                        {
                          required: isEdit ? false : true,
                          message: t("common.required"),
                        },
                        {
                          validator(rule, value, callback) {
                            if (!value) Promise.resolve();
                            if (value?.length < 6) {
                              return Promise.reject(t("common.password_620"));
                            }
                            if (!/^(?=.*[a-z])(?=.*[A-Z]).+$/.test(value)) {
                              return Promise.reject(
                                t("common.password_character")
                              );
                            }
                            if (
                              value?.indexOf(" ") > -1 ||
                              !/^[^\s\u00C0-\u024F]+$/.test(value)
                            ) {
                              return Promise.reject(t("common.password_space"));
                            }
                            return Promise.resolve();
                          },
                        },
                      ]}
                    >
                      <Input.Password
                        maxLength={200}
                        autoComplete="one-time-code"
                        placeholder={t("user.password")}
                      />
                    </Form.Item>
                  </Col>

                  <Col span={24}>
                    <Form.Item
                      dependencies={["password"]}
                      hidden={isEdit}
                      label={t("user.password_confirm")}
                      name={["password_confirm"]}
                      rules={[
                        {
                          required: isEdit ? false : true,
                          message: t("common.required"),
                        },
                        ({ getFieldValue }) => ({
                          validator(rule, value) {
                            if (!value) {
                              return Promise.resolve();
                            }
                            if (getFieldValue("password") != value) {
                              return Promise.reject(t("common.password_same"));
                            }
                            return Promise.resolve();
                          },
                        }),
                      ]}
                    >
                      <Input.Password
                        autoComplete="one-time-code"
                        placeholder={t("user.password_confirm")}
                        maxLength={200}
                      />
                    </Form.Item>
                  </Col>
                </>
              )}
            </Row>
          </Col>
        </Row>
      </Form>

      <Modal
        {...infoModalProps}
        title={t("company.change_admin_info")}
        width={400}
        okText={t("company.change_admin_save")}
        cancelText={t("company.change_admin_close")}
      >
        <Form
          {...infoFormProps}
          layout="vertical"
          initialValues={{
            email: formProps.initialValues?.user?.email,
            id: formProps.initialValues?.user?.id,
            phone: formProps.initialValues?.user?.phone?.replace("+84", ""),
          }}
        >
          <Form.Item name={["id"]} hidden>
            <Input maxLength={200} />
          </Form.Item>
          <Form.Item
            label={t("company.admin_email")}
            name={["email"]}
            rules={[
              {
                required: true,
                message: t("common.required"),
              },
              {
                type: "email",
                message: t("common.wrong_email"),
              },
            ]}
          >
            <Input maxLength={200} />
          </Form.Item>
          <Form.Item
            label={t("company.admin_phone")}
            name={["phone"]}
            rules={[
              {
                required: true,
                message: t("common.required"),
              },
              {
                min: 8,
                max: 11,
              },
            ]}
          >
            <Input maxLength={200} addonBefore="+84" />
          </Form.Item>
        </Form>
      </Modal>

      <Modal
        {...passwordModalProps}
        title={t("company.change_admin_password")}
        width={400}
        okText={t("company.change_admin_save")}
        cancelText={t("company.change_admin_close")}
      >
        <Form {...passwordFormProps} layout="vertical">
          <Form.Item name={["userId"]} hidden>
            <Input maxLength={200} />
          </Form.Item>
          <Form.Item
            label={t("user.password")}
            name={["password"]}
            rules={[
              {
                required: isEdit ? false : true,
                message: t("common.required"),
              },
              {
                validator(rule, value, callback) {
                  if (!value) Promise.resolve();
                  if (value?.length < 6) {
                    return Promise.reject(t("common.password_620"));
                  }
                  if (!/^(?=.*[a-z])(?=.*[A-Z]).+$/.test(value)) {
                    return Promise.reject(t("common.password_character"));
                  }
                  if (
                    value?.indexOf(" ") > -1 ||
                    !/^[^\s\u00C0-\u024F]+$/.test(value)
                  ) {
                    return Promise.reject(t("common.password_space"));
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Input.Password
              maxLength={200}
              autoComplete="one-time-code"
              placeholder={t("user.password")}
            />
          </Form.Item>
          <Form.Item
            dependencies={["password"]}
            hidden={isEdit}
            label={t("user.password_confirm")}
            name={["password_confirm"]}
            rules={[
              {
                required: isEdit ? false : true,
                message: t("common.required"),
              },
              ({ getFieldValue }) => ({
                validator(rule, value) {
                  if (!value) {
                    return Promise.resolve();
                  }
                  if (getFieldValue("password") != value) {
                    return Promise.reject(t("common.password_same"));
                  }
                  return Promise.resolve();
                },
              }),
            ]}
          >
            <Input.Password
              autoComplete="one-time-code"
              placeholder={t("user.password_confirm")}
              maxLength={200}
            />
          </Form.Item>
        </Form>
      </Modal>
    </Create>
  );
};
