import React from "react";
import { IResourceComponentsProps } from "@refinedev/core";
import { Card } from "antd";

import { useSearchParams } from "react-router-dom";
import ViewImage from "components/view_image";
import { baseURL, TOKEN_KEY } from "authProvider";

export const ViewFile: React.FC<IResourceComponentsProps> = () => {
  const [searchParams] = useSearchParams();
  const filename = searchParams.get("name");

  const exten = filename?.split(".").pop()?.toLowerCase() || "";
  const linkdown = `${baseURL}/view-file/${filename}?token=${localStorage.getItem(
    TOKEN_KEY
  )}`;
  return (
    <Card>
      {["pdf", "doc", "docx", "xls", "xlxs"].includes(exten) ? (
        <a href={linkdown} target="_blank">
          {filename}
        </a>
      ) : (
        <ViewImage preview={false} value={filename || ""} />
      )}
    </Card>
  );
};
